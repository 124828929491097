import { LearningClassModel } from '../models/LearningClassModel';
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const LIST_LEARNING_CLASS = `${API_URL}/api/v1/learning/class/list`
export const CREATE_LEARNING_CLASS = `${API_URL}/api/v1/learning/class/create`
export const GET_LEARNING_CLASS = `${API_URL}/api/v1/learning/class/show/`
export const UPDATE_LEARNING_CLASS = `${API_URL}/api/v1/learning/class/update`
export const DELETE_LEARNING_CLASS = `${API_URL}/api/v1/learning/class/delete/`
export const EXPORT_ORDER = `${API_URL}/api/v1/order/export`

const getAllClass = (limit: any, page: any, sort_column: any, sort_type: any, relationships: any, auth: any) => {
 
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type,
  }

  if(auth.type === "academic_affair" && auth.center_id > 0){
    Object.assign(filter, {center_id: auth.center_id})
  }

  let query = new URLSearchParams(filter).toString()
  if (Array.isArray(relationships)) {
    relationships.forEach((item, index) => {
      query += `&relationships=${item}`
    })
  }
  return axios.get<LearningClassModel>(LIST_LEARNING_CLASS + '?' + query);
}

const getWithPage = (limit: any, page: any, sort_column: any, sort_type: any, relationships: any, auth:any) => {
 
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type,
  }

  if(auth.type === "teacher"){
    Object.assign(filter, {teacher_id: auth.id});
  } else if(auth.type === "academic_affair" && auth.center_id > 0){
    Object.assign(filter, {center_id: auth.center_id})
  } else if(auth.type === "student"){
    Object.assign(filter, {student_id: auth.id})
  }
  
  let query = new URLSearchParams(filter).toString()
  if (Array.isArray(relationships)) {
    relationships.forEach((item, index) => {
      query += `&relationships=${item}`
    })
  }
  return axios.get<LearningClassModel>(LIST_LEARNING_CLASS + '?' + query);
}

const getWithPageSearch = (limit: any, page: any, sort_column: any, sort_type: any, relationships: any, className: any, classTeacher: any, classAcademicAffair: any, classCenter: any, classType: any, product: any, auth: any) => {
 
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type,
    search: className,
    teacher_id: classTeacher,
    product_id: product,
    academic_affair_id: classAcademicAffair,
    class_type: classType,
    center_id: classCenter
  }

  if(auth.type === "teacher"){
    Object.assign(filter, {teacher_id: auth.id})
  } else if(auth.type === "academic_affair" && auth.center_id > 0){
    Object.assign(filter, {center_id: auth.center_id})
  } else if(auth.type === "student"){
    Object.assign(filter, {student_id: auth.id})
  }

  let query = new URLSearchParams(filter).toString()
  if (Array.isArray(relationships)) {
    relationships.forEach((item, index) => {
      query += `&relationships=${item}`
    })
  }
  return axios.get<LearningClassModel>(LIST_LEARNING_CLASS + '?' + query);
}

const deleteWithId = (learningClass: any) => {
  return axios.delete<LearningClassModel>(DELETE_LEARNING_CLASS + learningClass?.class_id, learningClass);
}

const createLearningClass = (learningClass: any) => {
  learningClass.teacher_salary = parseFloat(learningClass.teacher_salary);
  learningClass.class_schedule = JSON.stringify(learningClass.schedules);
  return axios.post<LearningClassModel>(CREATE_LEARNING_CLASS, learningClass);
}

const getLearningClassById = (class_id: number) => {
  return axios.get<LearningClassModel>(GET_LEARNING_CLASS + class_id);
}

const updateLearningClassById = (learningClass: any) => {
  learningClass.teacher_salary = parseFloat(learningClass.teacher_salary);
  learningClass.class_schedule = JSON.stringify(learningClass.schedules);
  return axios.put<LearningClassModel>(UPDATE_LEARNING_CLASS, learningClass);
}

const getWithPageSolo = (limit: any, page: any, sort_column: any, sort_type: any, relationships: any, auth: any) => {
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type,
    class_type: "solo"
  }

  if(auth.type === "teacher"){
    Object.assign(filter, {teacher_id: auth.id})
  } else if(auth.type === "academic_affair" && auth.center_id > 0){
    Object.assign(filter, {center_id: auth.center_id})
  } else if(auth.type === "student"){
    Object.assign(filter, {student_id: auth.id})
  }

  let query = new URLSearchParams(filter).toString()
  if (Array.isArray(relationships)) {
    relationships.forEach((item, index) => {
      query += `&relationships=${item}`
    })
  }
  return axios.get<LearningClassModel>(LIST_LEARNING_CLASS + '?' + query);
}

const getWithPageCollective = (limit: any, page: any, sort_column: any, sort_type: any, relationships: any, auth: any) => {
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type,
    class_type: "collective"
  }

  if(auth.type === "teacher"){
    Object.assign(filter, {teacher_id: auth.id})
  } else if(auth.type === "academic_affair" && auth.center_id > 0){
    Object.assign(filter, {center_id: auth.center_id})
  } else if(auth.type === "student"){
    Object.assign(filter, {student_id: auth.id})
  }

  let query = new URLSearchParams(filter).toString()
  if (Array.isArray(relationships)) {
    relationships.forEach((item, index) => {
      query += `&relationships=${item}`
    })
  }
  return axios.get<LearningClassModel>(LIST_LEARNING_CLASS + '?' + query);
}

const getWithPageGroup = (limit: any, page: any, sort_column: any, sort_type: any, relationships: any, auth: any) => {
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type,
    class_type: "group"
  }

  if(auth.type === "teacher"){
    Object.assign(filter, {teacher_id: auth.id})
  } else if(auth.type === "academic_affair" && auth.center_id > 0){
    Object.assign(filter, {center_id: auth.center_id})
  } else if(auth.type === "student"){
    Object.assign(filter, {student_id: auth.id})
  }

  let query = new URLSearchParams(filter).toString()
  if (Array.isArray(relationships)) {
    relationships.forEach((item, index) => {
      query += `&relationships=${item}`
    })
  }
  return axios.get<LearningClassModel>(LIST_LEARNING_CLASS + '?' + query);
}

const getExport = (startDate: any, endDate: any) => {
  let filter = {
      limit: '100',
      page: '1'
  }
  let query = new URLSearchParams(filter).toString();
  [startDate + " 00:00:00", endDate + " 23:59:59"].forEach((item, index) => {
      query += `&created_at=${item}`
  })
  return axios.get<ArrayBuffer>(EXPORT_ORDER + '?' + query, {responseType : 'arraybuffer', headers : {'Content-Type': 'application/octet-stream'}});
}

const LearningClassService = {
    getAllClass,
    getWithPage,
    deleteWithId,
    createLearningClass,
    getLearningClassById,
    updateLearningClassById,
    getWithPageSolo,
    getWithPageCollective,
    getWithPageGroup,
    getWithPageSearch,
    getExport
  };
  
export default LearningClassService;