/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useDispatch} from 'react-redux'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap-v5'
import AccountsService from '../../../../app/modules/settings/redux/AccountsCRUD'
import { Field, FieldArray, Form, Formik } from 'formik';
import { array, boolean, number, object, string, ValidationError } from 'yup';
import MultiSelect from "../products/MultiSelect";
import {useIntl} from 'react-intl'
import Select from 'react-select';
import EnrollmentsService from '../../../../app/modules/assets/redux/EnrollmentsCRUD'
import {EnrollmentsModel} from '../../../../app/modules/assets/models/EnrollmentsModel'
import Pagination from '../../../../app/components/Pagination'
import moment from"moment";
import {useUser} from '../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const enrollmentSchema = Yup.object().shape({
  class_id: Yup.number()
    .min(1, 'Lớp học cần được chọn'),
  discount_id: Yup.number()
    .min(1, 'Hình thức khuyến mại cần được chọn'),
})

export type Props = {
  setIsReload: any;
  accounts: any;
  setAccounts: any;
  showDetailModal: boolean;
  handleCloseDetailModal: any;
  initialValues: any;
  notify: any;
  learningClasses: any;
  discounts: any;
  initialValuesEnrollments: any;
  setInitialValuesEnrollments: any;
  isDisableClass: any;
  setIsDisableClass: any;
}

export default function StudentDetailModal({
  setIsReload,
  accounts,
  setAccounts,
  showDetailModal,
  handleCloseDetailModal,
  initialValues,
  notify,
  learningClasses,
  discounts,
  initialValuesEnrollments,
  setInitialValuesEnrollments,
  isDisableClass,
  setIsDisableClass
}: Props) {
  const [loading, setLoading] = useState(false)
  
  const [label, setLabel] = useState("Thêm học viên vào lớp");
  const dispatch = useDispatch();
  const [discountItem, setDiscountItem] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [enrollments, setEnrollments] = useState<Array<EnrollmentsModel>>([]);
  const [classes, setClasses] = useState<any>([]);
  const auth = useUser();

  useEffect(() => {
    retrieveEnrollments();
  }, [initialValues])

  useEffect(() => {
    // init learningCenters for user
    if(auth.center_id){
      setClasses(learningClasses.filter((option: any) => auth.center_id.toString().indexOf(option.center_id) >= 0));
    } else {
      setClasses(learningClasses);
    }

    if(initialValuesEnrollments.enrollment_id > 0){
      // set label
      setLabel("Cập nhật lớp học của học viên");  
    } else {
      setLabel("Thêm học viên vào lớp");
    }
  }, [initialValuesEnrollments])

  const retrieveEnrollments = () => {
    EnrollmentsService.getClassForStudent(limit, currentPage, 'enrollment_id', 'DESC', initialValues.id)
      .then((response: any) => {
        setEnrollments(response.data.data.rows);
        setTotalRecord(response.data.data.total_rows);
        setLastPage(response.data.data.total_pages);
      })
      .catch((e: Error) => {
        // console.log(e);
      });
  };

  const handleEdit = (enrollment: any) => {
    let classInfo = learningClasses.find((option: any) => enrollment.class_id.toString().indexOf(option.value) >= 0);
    enrollment.total_lesson = parseInt(classInfo.total);
    setInitialValuesEnrollments(enrollment);

    // set discount item
    setDiscountItem(discounts.filter((option: any) => JSON.parse(classInfo.discount).indexOf(option.value) >= 0));

    // set label
    setLabel("Cập nhật lớp học của học viên");

    // set disabled
    setIsDisableClass(true);
  };

  const handleRemoveStudentFromClass = (enrollment: any) => {
    MySwal.fire({
      title: "Lưu ý",
      html: `
        Học viên sau khi bị gỡ khỏi lớp thì giao dịch sẽ được cập nhật trạng thái về huỷ bỏ.
      `,
      confirmButtonText: `
        Xoá học viên khỏi lớp&nbsp;<i class="fa fa-arrow-right"></i>
      `,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        enrollment.status = 3;
        EnrollmentsService.update(enrollment)
          .then((response: any) => {
            // set remove enrollment
            deleteElement(enrollment);

            // notify
            notify("Bạn đã xoá học viên khỏi lớp thành công", "show");
          })
          .catch(() => {
            notify("Có lỗi xảy ra, bạn cần liên hệ quản trị viên để kiểm tra", "show");
          });
      }
    });
  };

  const deleteElement = (enrollment: any) => {
    setEnrollments(enrollments.filter(item => item?.enrollment_id !== enrollment?.enrollment_id));
  };

  return (
    <Modal
      // className='bg-white'
      id='kt_modal_accounts'
      aria-hidden='true'
      dialogClassName='modal-fullscreen'
      show={showDetailModal}
      onHide={handleCloseDetailModal}
      animation={true}
    >
      <div className='modal-header pb-0 border-0 justify-content-end'>
        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={handleCloseDetailModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </div>
      </div>
      <div className='modal-body scroll-y'>        
        <div id="kt_content_container" className="container-xxl">
          <div className="row" id="kt_content">
            <div className='col-lg-12 fv-row fv-plugins-icon-container mb-10 menu menu-sub menu-sub-dropdown show p-5'>
            <Formik
              initialValues={initialValuesEnrollments}
              enableReinitialize={true}
              validationSchema={enrollmentSchema}
              onSubmit={async (values, {resetForm, setStatus, setSubmitting}) => {
                setLoading(true);

                setTimeout(() => {
                  setInitialValuesEnrollments({
                    enrollment_id: 0,
                    class_id: 0,
                    user_id: 0,
                    status: 1,
                    total_lesson: 0,
                    total_discount_percent: 0,
                    discount_id: 0
                  });

                  values.total_lesson_promote = parseInt(values.total_lesson_promote);
                  values.total_lesson         = parseInt(values.total_lesson) + parseInt(values.total_lesson_promote);

                  if(values?.enrollment_id && values.enrollment_id){
                    EnrollmentsService.update(values)
                      .then((response: any) => {
                        const newState = enrollments.map((obj: any) => {
                          if (obj.enrollment_id === values.enrollment_id) {
                            return {...obj, discount_id: values.discount_id, total_discount_percent: values.total_discount_percent, total_lesson_promote: values.total_lesson_promote, status: response.data.data.status};
                          }
                          return obj;
                        });
                        setEnrollments(newState);

                        // set label
                        setLabel("Thêm học viên vào lớp");

                        // set disabled
                        setIsDisableClass(false);
  
                        // set loading
                        setLoading(false);
  
                        // reset form
                        resetForm();
  
                        // notify
                        notify("Bạn đã cập nhật học viên vào lớp thành công", "show");
                      })
                      .catch(() => {
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('Có lỗi xảy ra mời bạn liên hệ quản trị hệ thống để hỗ trợ.')
                      })
                  } else {
                    // create new name
                    EnrollmentsService.create(values)
                      .then((response: any) => {
                        setEnrollments([response.data.data, ...enrollments])
  
                        // set loading
                        setLoading(false);
  
                        // reset form
                        resetForm();
  
                        // notify
                        notify("Bạn đã gán học viên vào lớp thành công", "show");
                      })
                      .catch(() => {
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('Có lỗi xảy ra mời bạn liên hệ quản trị hệ thống để hỗ trợ.')
                      })
                  }
                }, 1000)
              }}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  isValid
                } = props;
                  function customHandleChange(e: any) {
                    // set props
                    props.setFieldValue("class_id", e.value);
                    props.setFieldValue("user_id", initialValues.id);
                    props.setFieldValue("total_discount_percent", 0);
    
                    // set discounts
                    let classInfo = learningClasses.find((option: any) => e.value.toString().indexOf(option.value) >= 0);
                    setDiscountItem(discounts.filter((option: any) => JSON.parse(classInfo.discount).indexOf(option.value) >= 0));

                    // set enrollment total lesson
                    if(classInfo){
                      props.setFieldValue("total_lesson", parseInt(classInfo.total));
                    }
                  }

                  function customHandleChangeDiscount(e: any){
                    // set props
                    props.setFieldValue("discount_id", e.value);

                    let discountInfo = discountItem.find((option: any) => e.value.toString().indexOf(option.value.toString()) >= 0);
                    
                    // set discount percent
                    if(discountInfo){
                      props.setFieldValue("total_discount_percent", parseInt(discountInfo.percent));

                      // set inital values
                      setInitialValuesEnrollments({
                        ...values,
                        discount_id: e.value,
                        total_discount_percent: parseInt(discountInfo.percent)
                      })
                    }
                  }

                  return (
                    <Form autoComplete="off">
                      <div className="row" id="kt_content">
                        <div className="col-md-12">
                          <label className='form-label fs-6 fw-bolder text-dark'>Lớp học</label>
                          <Select
                            options={classes}
                            name='class_id'
                            onChange={customHandleChange}
                            onBlur={handleBlur}
                            placeholder="Chọn lớp học"
                            value={learningClasses ? learningClasses.find((option: any) => option.value === initialValuesEnrollments.class_id) : ''}
                            isDisabled={isDisableClass}
                          />
                          {errors.class_id && errors.class_id && (
                            <div className='fv-plugins-message-container text-danger mt-2'>
                              <span role='alert'>{errors.class_id}</span>
                            </div>
                          )}
                        </div>

                        <div className="col-md-6 mt-5">
                          <label className='form-label fs-6 fw-bolder text-dark'>Khuyến mại</label>
                          <Select
                            options={discountItem}
                            name='discount_id'
                            onChange={customHandleChangeDiscount}
                            onBlur={handleBlur}
                            placeholder="Chọn khuyến mại"
                            value={discountItem ? discountItem.find((option: any) => option.value === initialValuesEnrollments.discount_id) : ''}
                          />
                          {errors.discount_id && errors.discount_id && (
                            <div className='fv-plugins-message-container text-danger mt-2'>
                              <span role='alert'>{errors.discount_id}</span>
                            </div>
                          )}
                        </div>

                        <div className="col-md-6 mt-5">
                          <label className='form-label fs-6 fw-bolder text-dark'>Số buổi được khuyến mại thêm</label>
                          <Field
                            fullWidth
                            name="total_lesson_promote"
                            type="text"
                            className='form-control form-control-lg'
                            placeholder='Số buổi được khuyến mại thêm'
                          />
                          {errors.total_lesson_promote && errors.total_lesson_promote && (
                            <div className='fv-plugins-message-container text-danger mt-2'>
                              <span role='alert'>{errors.total_lesson_promote}</span>
                            </div>
                          )}
                        </div>

                        <div className="col-md-12 mt-5">
                          <label className='form-label fs-6 fw-bolder text-dark'>Số buổi đã học (Chỉ mở ra cho lần đầu nhập liệu)</label>
                          <Field
                            fullWidth
                            name="total_lesson_learned"
                            type="text"
                            className='form-control form-control-lg'
                            placeholder='Số buổi đã học'
                          />
                          {errors.total_lesson_learned && errors.total_lesson_learned && (
                            <div className='fv-plugins-message-container text-danger mt-2'>
                              <span role='alert'>{errors.total_lesson_learned}</span>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* begin::Action */}
                      <div className='text-center mt-5'>
                        <button
                          type='reset'
                          className='btn btn-xs btn-white btn-active-light-primary me-2'
                          data-kt-menu-dismiss='true'
                          onClick={handleCloseDetailModal}
                        >
                          Đóng màn hình
                        </button>

                        <button
                          type='submit'
                          id='kt_sign_in_submit'
                          className='btn btn-xs btn-primary'
                          disabled={isSubmitting || !isValid}
                        >
                          {!loading && <span className='indicator-label'>{label}</span>}
                          {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Vui lòng chờ trong giây lát...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                      {/* end::Action */}

                      {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                    </Form>
                  );
                }}
              </Formik>
            </div>

            <div className='col-lg-12 fv-row fv-plugins-icon-container mb-10 p-10 pt-0'>
              <div className='mb-0'>
                <h5 className='form-label fs-6 fw-bolder text-dark'>Lịch sử tham gia lớp học</h5>
              </div>
              {/* begin::Table */}
              <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='1'
                          data-kt-check='true'
                          data-kt-check-target='.widget-9-check'
                        />
                      </div>
                    </th>
                    <th className='min-w-100px'>Tên lớp học</th>
                    <th className='min-w-100px'>Tổng số buổi / KM</th>
                    <th className='min-w-100px'>Số buổi đã học</th>
                    <th className='min-w-100px'>Trạng thái</th>
                    <th className='min-w-100px'>Ngày bắt đầu</th>
                    <th className='min-w-100px'>Ngày cập nhật</th>
                    <th className='min-w-100px text-end'>Hành động</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {enrollments?.map((enrollment: any) => (
                <tr key={enrollment?.enrollment_id}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input className='form-check-input widget-9-check' type='checkbox' value={enrollment?.enrollment_id} />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a className='text-dark text-hover-primary fs-6'>
                          {enrollment.class.class_name}
                        </a>
                        <span className="accordion-icon text-muted">
                          <KTSVG
                            className="svg-icon svg-icon-4"
                            path="/media/icons/duotune/coding/cod004.svg"
                          /> Khuyến mại: {enrollment.total_discount_percent} %
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a className='text-dark text-hover-primary fs-6'>
                          {enrollment.total_lesson} / {enrollment.total_lesson_promote}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a className='text-dark text-hover-primary fs-6'>
                          {enrollment.total_lesson_learned}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a className='text-dark text-hover-primary fs-6'>
                          {enrollment.status === 1? (
                            <span className='text-success fw-bold d-block'><i className="las la-check text-success"></i> Hoạt động</span>
                          ):(
                            <>
                              {enrollment.status === 2? (
                                <span className='text-success fw-bold d-block'><i className="las la-check text-success"></i> Đã hoàn thành khoá học</span>
                              ):(
                                <span className='text-danger fw-bold d-block'><i className="las la-window-close text-danger"></i> Đang bảo lưu</span>
                              )}
                            </>
                          )}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a  className='text-dark text-hover-primary fs-6'>
                          {(moment(enrollment?.created_at).isValid())?moment(enrollment?.created_at).format("DD/MM/YYYY kk:mm:ss"):"-"}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a  className='text-dark text-hover-primary fs-6'>
                          {(moment(enrollment?.updated_at).isValid())?moment(enrollment?.updated_at).format("DD/MM/YYYY kk:mm:ss"):"-"}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                        onClick={() => handleEdit(enrollment)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                      </a>

                      <a
                        onClick={() => handleRemoveStudentFromClass(enrollment)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </td>
                </tr>
                ))}
                </tbody>
              </table>

              <Pagination
                currentPage={currentPage}
                lastPage={lastPage}
                maxLength={7}
                setCurrentPage={setCurrentPage}
                limit={limit}
                totalRecord={totalRecord}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
