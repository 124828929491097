import React from 'react';
import { Route, Redirect } from 'react-router-dom';

interface Props{
  component: any;
  hasPermission: boolean;
  path:string;
}

const ProtectedRoute: React.FC<Props> = ({ component: Component, hasPermission, path }) => {

  return (
      <Route
          {...path}
          render={(props) => (
              hasPermission
                  ? <Component {...props} />
                  : <Redirect to="/dashboard" />
          )}
      />
  );
};

export default ProtectedRoute;
