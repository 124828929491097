import axios from 'axios'
import {StudentsModel} from '../models/StudentsModel'
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const EXPORT_ACCOUNT = `${API_URL}/api/v1/user/export`
export const LIST_ACCOUNT = `${API_URL}/api/v1/user/list`
export const LIST_AUDIT_LOG = `${API_URL}/api/v1/system/audit-log/list`
export const CREATE_ACCOUNT = `${API_URL}/api/v1/auth/register`
export const GET_ACCOUNT = `${API_URL}/api/v1/user/show/:id`
export const UPDATE_ACCOUNT = `${API_URL}/api/v1/user/update`
export const DELETE_ACCOUNT = `${API_URL}/api/v1/user/delete/`
export const CHANGE_PASSWORD_ACCOUNT = `${API_URL}/api/v1/user/update-password`

const getWithPage = (limit: any, page: any, sort_column: any, sort_type: any, email: any, status: any, uid: any, startDate: any, endDate: any, type: any, typeSearch: any) => {
  if(typeSearch){
    let filter = {
      limit, 
      page, 
      sort_column, 
      sort_type,
      type: "student",
      search: email,
      status,
      user_id: (isNaN(uid)) ? 0 : uid,
      relationships: 'roles'
    }

    let query = new URLSearchParams(filter).toString();
    if(startDate === ""){
      startDate = "1970-01-01 00:00:00"
    }
    if(endDate === ""){
      endDate = moment().format("YYYY-MM-DD 23:59:59")
    }
    [startDate, endDate].forEach((item, index) => {
      query += `&created_at=${item}`
    })

    return axios.get<StudentsModel>(LIST_ACCOUNT + '?' + query);
  } else {
    let filter = {
      limit, 
      page, 
      sort_column, 
      sort_type,
      type: "student",
      relationships: 'roles'
    }
    return axios.get<StudentsModel>(LIST_ACCOUNT + '?' + new URLSearchParams(filter).toString());
  }
}

const getExport = (limit: any, page: any, sort_column: any, sort_type: any, email: any, status: any, uid: any, startDate: any, endDate: any, type: any) => {
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type,
    type: "student",
    search: email,
    status,
    user_id: (isNaN(uid)) ? 0 : uid,
  }

  let query = new URLSearchParams(filter).toString();
  if(startDate === ""){
    startDate = "1970-01-01 00:00:00"
  }
  if(endDate === ""){
    endDate = moment().format("YYYY-MM-DD 23:59:59")
  }
  [startDate, endDate].forEach((item, index) => {
    query += `&created_at=${item}`
  })
  return axios.get<ArrayBuffer>(EXPORT_ACCOUNT + '?' + query, {responseType : 'arraybuffer', headers : {'Content-Type': 'application/octet-stream'}});
}

const getAuditLogs = (limit: any, page: any, sort_column: any, sort_type: any) => {
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type
  }
  return axios.get<StudentsModel>(LIST_AUDIT_LOG + '?' + new URLSearchParams(filter).toString());
}

const deleteWithId = (account: any) => {
  return axios.delete<StudentsModel>(DELETE_ACCOUNT + account?.id);
}

const createAccount = (account: any) => {
  return axios.post<any>(CREATE_ACCOUNT, {
    email: account.email,
    name: account.name,
    password: account.password,
    type: "student",
    phone_number: account.phone_number,
    reference_email: account.reference_email,
    reference_name: account.reference_name,
    reference_phone: account.reference_phone,
    birth_day : parseInt(account.birth_day),
    birth_year : parseInt(account.birth_year),
    birth_month : parseInt(account.birth_month),
    address: account.address,
    source: account.source,
    roles: [{
      id: parseInt(account.roles)
    }]
  });
}

const updateContactById = (account: any) => {
  account.status = parseInt(account?.status);
  account.roles  = [{
    id: parseInt(account.roles)
  }];
  account.birth_day = parseInt(account.birth_day);
  account.birth_year = parseInt(account.birth_year);
  account.birth_month = parseInt(account.birth_month);
  if(account.center_id === 0){
    delete account.center_id;
  }
  return axios.put<StudentsModel>(UPDATE_ACCOUNT, account);
}

const getAccountById = (id: number) => {
  return axios.get<StudentsModel>(GET_ACCOUNT + id);
}

const updatePasswordById = (account: StudentsModel) => {
  return axios.put<StudentsModel>(CHANGE_PASSWORD_ACCOUNT, account);
}

const AccountsService = {
    getWithPage,
    getAuditLogs,
    deleteWithId,
    createAccount,
    getAccountById,
    updateContactById,
    updatePasswordById,
    getExport
  };
  
export default AccountsService;