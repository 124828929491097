/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export type Props = {
  setType: (type: string) => void;
  setStatus: (status: number) => void;
  setEmail: (email: string) => void;
  findAccounts: any;
};

export function TeachersFilter({
  setType,
  setStatus,
  setEmail,
  findAccounts
}: Props) {
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-400px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Bộ lọc tài khoản</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-5'>
          <label className='form-label fw-bold'>Tên tài khoản / Mobile:</label>

          <div className='flex-grow-1'>
            <input
            placeholder='Nhâp tự khoá'
            className='form-control form-control-lg form-control-solid'
            type='text'
            name='discount'
            autoComplete='off'
            onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>


        <div className='mb-10'>
          <label className='form-label fw-bold'>Trạng thái:</label>

          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={'0'}
              onChange={e => setStatus(parseInt(e.target.value))}
            >
              <option value='0'>Toàn bộ trạng thái</option>
              <option value='1'>Hoạt động</option>
              <option value='4'>Đã bị khoá</option>
            </select>
          </div>
        </div>

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-white btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Quay lại
          </button>

          <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true' onClick={() => findAccounts()}>
            Tìm kiếm
          </button>
        </div>
      </div>
    </div>
  )
}
