/* eslint-disable react/jsx-no-target-blank */
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, useCanAccess, useUser} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {UserModel} from "../../../../app/modules/auth/models/UserModel";
import {MenuItem} from "../header/MenuItem";


export function AsideMenuMain() {
  const intl = useIntl()
    const accessDiscount = useCanAccess('ProductDiscount', 'GetAll');
    const accessProduct = useCanAccess('Product', 'GetAll');
    const accessTeachers = useCanAccess('User', 'GetAll');
    const accessStudents = useCanAccess('User', 'GetAll');
    const accessSchedules = useCanAccess('LearningClassEnrollments', 'GetAll');
    const accessClasses = useCanAccess('LearningClass', 'GetAll');
    const accessCenters = useCanAccess('LearningCenter', 'GetAll');
    const accessRooms = useCanAccess('LearningRoom', 'GetAll');
    const accessAccounts = useCanAccess('User', 'GetAll');
    const accessVendors = useCanAccess('Vendor', 'GetAll');
    const accessProductTypes = useCanAccess('ProductType', 'GetAll');
    const accessNotifications = useCanAccess('NotificationSetting', 'GetAll');
    const accessVariantGroups = useCanAccess('VariantGroup', 'GetAll');
    const accessVariants = useCanAccess('Variant', 'GetAll');
    const accessRole = useCanAccess('System', 'GetAllRole');
    const accessCompetition = useCanAccess('LearningCompetition', 'GetAll');
    const auth = useUser();

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Tổng quan'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Cài đặt giao diện'
        fontIcon='bi-layers'
      />
      {(accessProduct || accessTeachers || accessStudents) && (auth.type === "administrator" || auth.type === "academic_affair") && (
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Quản lý tài nguyên</span>
        </div>
      </div>)}
        {accessProduct && (auth.type === "administrator" || auth.type === "academic_affair") && (<AsideMenuItem to='/assets/products' title='Quản lý khoá học' fontIcon='bi-archive' icon='/media/icons/duotune/abstract/abs046.svg'/>)}
        {accessTeachers && (<AsideMenuItem to='/assets/teachers' title='Quản lý giáo viên' fontIcon='bi-archive' icon='/media/icons/duotune/technology/teh005.svg'/>)}
        {accessStudents && (<AsideMenuItem to='/assets/students' title='Quản lý học viên' fontIcon='bi-archive' icon='/media/icons/duotune/technology/teh002.svg'/>)}

        {(accessSchedules || accessClasses) && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Quản lý tiến trình học</span>
          </div>
        </div>)}
        {accessSchedules && (<AsideMenuItem to='/learning/schedules' title='Quản lý lịch học' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen014.svg'/>)}
        {accessClasses && (<AsideMenuItem to='/learning/classes' title='Quản lý lớp học' fontIcon='bi-archive' icon='/media/icons/duotune/abstract/abs046.svg'/>)}
        {(accessAccounts || accessNotifications || accessRole) && (
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Cài đặt hệ thống</span>
        </div>
      </div>)}
        {accessAccounts && (<AsideMenuItem to='/settings/accounts' title='Danh sách tài khoản' fontIcon='bi-archive' icon='/media/icons/duotune/communication/com005.svg'/>)}
        {accessNotifications && (<AsideMenuItem to='/settings/notifications' title='Quản lý notification' fontIcon='bi-archive' icon='/media/icons/duotune/electronics/elc007.svg'/>)}
        {accessRole && (<AsideMenuItem to='/settings/roles' title='Quản lý vai trò' fontIcon='bi-archive' icon='/media/icons/duotune/coding/cod007.svg'/>)}
        {(accessCenters || accessRooms) && (
        <AsideMenuItemWithSub
        to='/settings/learning'
        title='Cài đặt lớp học'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/ecommerce/ecm008.svg'
      >
          {accessCenters && (<AsideMenuItem to='/settings/learning/centers' title='Danh sách cơ sở' hasBullet={true}/>)}
          {accessRooms && (<AsideMenuItem to='/settings/learning/rooms' title='Danh sách phòng học' hasBullet={true}/>)}
      </AsideMenuItemWithSub>
        )}
      {(accessDiscount || accessProductTypes || accessVendors || accessVariantGroups || accessVariants) && (
      <AsideMenuItemWithSub
        to='/settings/variant'
        title='Cài đặt khoá học'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen025.svg'
      >
          {accessDiscount && (<AsideMenuItem to='/settings/product/discount' title='Tỉ lệ discount' hasBullet={true}/>)}
          {accessProductTypes && (<AsideMenuItem to='/settings/product/product-types' title='Quản lý loại khóa học' hasBullet={true}/>)}
          {accessVendors && (<AsideMenuItem to='/settings/product/vendors' title='Phương pháp giảng dạy' hasBullet={true}/>)}
          {(accessVariantGroups || accessVariants) && (
          <AsideMenuItemWithSub to='/settings/product/variant' title='Cài đặt biến thể' hasBullet={true}>
          {accessVariantGroups && (<AsideMenuItem to='/settings/product/variant-groups' title='Nhóm biến thể' hasBullet={true} />)}
          {accessVariants && (<AsideMenuItem to='/settings/product/variants' title='Biến thể' hasBullet={true} />)}
        </AsideMenuItemWithSub>)}
      </AsideMenuItemWithSub>)}
      {(accessCompetition) && (auth.type === "administrator" || auth.type === "academic_affair") && (
      <AsideMenuItemWithSub
        to='/settings/variant'
        title='Quản lý sự kiện'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen020.svg'
      >
        <AsideMenuItem to='/competition/manage' title='Danh sách sự kiện' hasBullet={true}/>
        <AsideMenuItem to='/competition/topic' title='Quản lý bộ môn' hasBullet={true}/>
        <AsideMenuItem to='/competition/type' title='Quản lý nhóm thí sinh' hasBullet={true}/>
        <AsideMenuItem to='/competition/round' title='Quản lý vòng thi' hasBullet={true}/>
        <AsideMenuItem to='/competition/examinee' title='Quản lý thí sinh tham gia' hasBullet={true}/>
      </AsideMenuItemWithSub>)}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Tài khoản</span>
        </div>
      </div>
      <AsideMenuItem to='/logout' title='Đăng xuất' fontIcon='bi-archive' icon='/media/icons/duotune/arrows/arr077.svg'/>
      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
