import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {TeachersList} from './TeachersList';
import { useParams } from "react-router-dom";

const accountBreadCrumbs: Array<PageLink> = [
    {
      title: 'Assets',
      path: '/assets/teachers',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

interface Props{

}

const TeachersPage: React.FC<Props> = ({}) => {
  const params = useParams<{id: string}>();
  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Teachers</PageTitle>
      <TeachersList />
    </>
  )
}
export default TeachersPage