/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, ChangeEvent } from 'react'
import {KTSVG, TOAST, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLocation} from 'react-router'
import {TeachersModel} from './models/TeachersModel'
import AccountsService from './redux/TeachersCRUD'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {TeachersFilter} from './components/TeachersFilter'
import Pagination from '../../components/Pagination'
import moment from"moment";
import TeachersModal from '../../../_metronic/partials/modals/teachers/TeachersModal';
import { useParams } from "react-router-dom";
import { RoleModel, RolePermissionModel } from '../settings/models/RoleModel';
import RoleService from '../settings/redux/RoleCRUD';
import AccountPasswordModal from '../../../_metronic/partials/modals/settings/AccountPasswordModal';

const TeachersList: React.FC = () => {
  const params = useParams<{id: string}>();
  const location = useLocation()
  const [accounts, setAccounts] = useState<Array<TeachersModel>>([]);
  const [show, setShow] = useState(false);
  const [limit, setLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [toastMessage, setToastMessage] = useState("");
  const [toastClass, setToastClass] = useState("");
  const [accountDelete, setAccountDelete] = useState({});
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(0);
  const [type, setType] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [roles, setRoles] = useState<Array<RoleModel>>([]);
  const [isReload, setIsReload] = useState(false);
  const [initialValues, setInitialValues] = useState({
    id: 0,
    name: '',
    password: '',
    email: '',
    phone_number: '',
    status: 1,
    roles: 0,
    banking_name: '',
    banking_number: '',
  });
  const [auditLogs, setAuditLogs] = useState<Array<any>>([]);
  const [currentPageAuditLog, setCurrentPageAuditLog] = useState(1);
  const [lastPageAuditLog, setLastPageAuditLog] = useState(0);
  const [totalRecordAuditLog, setTotalRecordAuditLog] = useState(0);

  useEffect(() => {
    retrieveAccounts();
  }, [currentPage, isReload]);

  const retrieveAccounts = () => {
    AccountsService.getWithPage(limit, currentPage, 'id', 'DESC', email, status, type, false)
      .then((response: any) => {
        setAccounts(response.data.data.rows);
        setTotalRecord(response.data.data.total_rows);
        setLastPage(response.data.data.total_pages);

        // setup roles
        retrieveRoles();
      })
      .catch((e: Error) => {
        // console.log(e);
      });
  };

  const retrieveRoles = () => {
    RoleService.getWithPage(500, currentPage, 'id', 'DESC', "permissions")
      .then((response: any) => {
        setRoles(response.data.data.rows);
      })
      .catch((e: Error) => {
        // console.log(e);
      });
  }

  const findAccounts = () => {
    AccountsService.getWithPage(limit, currentPage, 'id', 'DESC', email, status, type, true)
      .then((response: any) => {
        setAccounts(response.data.data.rows);
        setTotalRecord(response.data.data.total_rows);
        setLastPage(response.data.data.total_pages);
      })
      .catch((e: Error) => {
        // console.log(e);
      });
  };

  const handleShow = (account: TeachersModel) => {
    setAccountDelete(account);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  }

  const handleDeleteAccount = () => {
    setShow(false);
    deleteAccount(accountDelete);
  };

  const deleteElement = (account: any) => {
    setAccounts(accounts.filter(item => item?.id !== account?.id));
  };

  const deleteAccount = (accountDelete: any) => {
    // call server
    AccountsService.deleteWithId(accountDelete)
    .then((response: any) => {
      // Xoa Discount
      deleteElement(accountDelete);

      // notify
      notify("Bạn đã xoá thành công", "show");
    })
    .catch((e: Error) => {
      // notify
      notify("Có lỗi với hệ thống, mời bạn thử lại sau", "show");
    });
  };

  const notify = (message: any, toastClass: any) => {
    setToastMessage(message);
    setToastClass(toastClass);
    setTimeout(() => {
      setToastMessage("");
      setToastClass("");
    }, 2000)
  }

  const handleShowCreateModal = () => {
    setShowCreateModal(true);
    setInitialValues({
      id: 0,
      name: '',
      password: '',
      email: '',
      phone_number: '',
      status: 1,
      roles: 0,
      banking_name: '',
      banking_number: '',
    });
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  }

  const handleEdit = (account: any) => {
    if(account.roles !== null && account.roles.length > 0){
      let roles = account.roles;
      account.roles = roles[0].id;
    } else {
      if(account.roles === 0 || account.roles.length === 0){
        account.roles = 0;
      }
    }
    setInitialValues(account);
    setShowCreateModal(true);
  };

  const handleShowPasswordModal = () => {
    setShowPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
  }

  const handleEditPassword = (account: any) => {
    setInitialValues(account);
    setShowPasswordModal(true);
  };

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Danh sách tài khoản</span>
        </h3>

        <div className='card-toolbar m-0'>
          <button
            type='button'
            className='btn btn-sm btn-light-primary m-2'
            onClick={() => handleShowCreateModal()}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen041.svg'
              className='svg-icon-3 svg-icon-primary'
            /> Thêm mới tài khoản giáo viên
          </button>
          
          <button
            type='button'
            className='btn btn-clean btn-sm btn-icon-primary btn-active-light-primary me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen024.svg'
              className='svg-icon-3 svg-icon-primary'
            /> Bộ lọc tìm kiếm
          </button>

          <TeachersFilter setEmail={setEmail} setStatus={setStatus} setType={setType} findAccounts={findAccounts} />
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-100px'>Tên tài khoản</th>
                <th className='min-w-100px'>Địa chỉ email</th>
                <th className='min-w-100px'>Số điện thoại</th>
                <th className='min-w-100px'>Thông tin bank</th>
                <th className='min-w-100px'>Loại tài khoản</th>
                <th className='min-w-100px'>Lần đăng nhập cuối</th>
                <th className='min-w-100px text-end'>Hành động</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {accounts?.map(account => (
              <tr key={account?.id}>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value={account.id} />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <a className='text-dark text-hover-primary fs-6'>
                        {account.name}
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <a  className='text-dark text-hover-primary fs-6'>
                        {account.email}
                      </a>
                      {account.status === 1? (
                        <span className='text-success fw-bold d-block'><i className="las la-check text-success"></i> Hoạt động</span>
                      ):(
                        <span className='text-danger fw-bold d-block'><i className="las la-window-close text-danger"></i> Đang bị khoá</span>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <a className='text-dark text-hover-primary fs-6'>
                        {account.phone_number}
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <a  className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    {(account.banking_name)?account.banking_name:'Không xác định'}
                  </a>
                  <span className='text-muted fw-bold d-block'>{(account.banking_number)?account.banking_number:'Không xác định'}</span>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <a  className='text-dark text-hover-primary fs-6'>
                        Giáo viên
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <a  className='text-dark text-hover-primary fs-6'>
                        {(moment(account?.last_time_login).isValid())?moment(account?.last_time_login).format("DD/MM/YYYY kk:mm:ss"):"-"}
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      onClick={() => handleEditPassword(account)}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/general/gen049.svg' className='svg-icon-3' />
                    </a>
                    <a
                      onClick={() => handleEdit(account)}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                    <a
                      onClick={() => handleShow(account)}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          
          <Pagination
            currentPage={currentPage}
            lastPage={lastPage}
            maxLength={7}
            setCurrentPage={setCurrentPage}
            limit={limit}
            totalRecord={totalRecord}
          />
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}

      {/* begin::Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Xác nhận</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bạn có chắc chắn muốn xóa?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Hủy bỏ
          </Button>
          <Button variant="primary" onClick={handleDeleteAccount}>
            Đồng ý
          </Button>
        </Modal.Footer>
      </Modal>
      <TOAST message={toastMessage} toastClass={toastClass} />
      {/* end::Modal */}

      <TeachersModal notify={notify} roles={roles} initialValues={initialValues} handleCloseCreateModal={handleCloseCreateModal} showCreateModal={showCreateModal} accounts={accounts} setAccounts={setAccounts} setIsReload={setIsReload} />
      <AccountPasswordModal notify={notify} initialValues={initialValues} handleClosePasswordModal={handleClosePasswordModal} showPasswordModal={showPasswordModal} accounts={accounts} setAccounts={setAccounts} setIsReload={setIsReload} />
    </div>
  )
}

export {TeachersList}
