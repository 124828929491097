import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {StudentsList} from './StudentsList';
import { useParams } from "react-router-dom";

const accountBreadCrumbs: Array<PageLink> = [
    {
      title: 'Assets',
      path: '/assets/students',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

interface Props{

}

const StudentsPage: React.FC<Props> = ({}) => {
  const params = useParams<{id: string}>();
  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Students</PageTitle>
      <StudentsList />
    </>
  )
}
export default StudentsPage