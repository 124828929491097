import React from 'react'
type Props = {
  message?: string
  toastClass?: string
}

const TOAST: React.FC<Props> = ({message = '', toastClass = ''}) => {
  return (
    <>
      {toastClass?
        <div className="position-fixed top-0 end-0 p-3 z-index-3">
          <div id="kt_docs_toast_toggle" className={'toast fade ' + toastClass} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-header">
                <strong className="me-auto">Polaris</strong>
                <small>{new Date().toLocaleString() + ""}</small>
                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div className="toast-body">
              {message}
            </div>
          </div>
        </div>
      :
        <div></div>
      }
    </>
  )
}

export {TOAST}
