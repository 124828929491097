/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useDispatch} from 'react-redux'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap-v5'
import AccountsService from '../../../../app/modules/settings/redux/AccountsCRUD'

const acountSchema = Yup.object().shape({
  password: Yup.string()
    .min(7, 'Mật khẩu ngắn nhất là 7 kí tự')
    .max(50, 'Mật khẩu dài nhất là 50 kí tự')
    .required('Mật khẩu tài khoản không được để trống'),
})

export type Props = {
  setIsReload: any;
  accounts: any;
  setAccounts: any;
  showPasswordModal: boolean;
  handleClosePasswordModal: any;
  initialValues: any;
  notify: any;
}

export default function AccountPasswordModal({
  setIsReload,
  accounts,
  setAccounts,
  showPasswordModal,
  handleClosePasswordModal,
  initialValues,
  notify,
}: Props) {
  const [loading, setLoading] = useState(false)
  const [label, setLabel] = useState("Đổi mật khẩu tài khoản");
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: acountSchema,
    onSubmit: (values, {resetForm, setStatus, setSubmitting}) => {
      resetForm();
      setLoading(true)
      setTimeout(() => {
        if(initialValues?.id && initialValues.id){
          // update contact
          AccountsService.updatePasswordById(values)
            .then((response: any) => {
              // close modal
              handleClosePasswordModal();

              // set loading
              setLoading(false);

              // reset form
              resetForm();

              // notify
              notify("Bạn đã cập nhật mật khẩu tài khoản giáo vụ thành công", "show");
            })
            .catch(() => {
              setLoading(false)
              setSubmitting(false)
              setStatus('Có lỗi của hệ thống, mời bạn thử lại')
            })
        }
      }, 1000)
    }
  })

  useEffect(() => {
    if(initialValues?.id){
      setLabel("Cập nhật mật khẩu tài khoản");
    }
  }, [initialValues])

  return (
    <Modal
      // className='bg-white'
      id='kt_modal_accounts'
      aria-hidden='true'
      dialogClassName='modal-dialog mw-650px'
      show={showPasswordModal}
      onHide={handleClosePasswordModal}
      animation={true}
    >
      <div className='modal-header pb-0 border-0 justify-content-end'>
        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={handleClosePasswordModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </div>
      </div>
      <div className='modal-body scroll-y'>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>Đổi mật khẩu tài khoản</h1>
        </div>
        
        {/* begin form */}
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            ""
          )}
          <input type='hidden' name='id' value={initialValues.id} />
            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mật khẩu</label>
                  {/* end::Label */}
                </div>
              </div>
              <input
                type='password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>{label}</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Vui lòng chờ trong giây lát...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}
          {/* end::Form group */}
        </form>
        {/* end form */}
      </div>
    </Modal>
  )
}
