/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import moment from "moment";

export type Props = {
  setType: (type: string) => void;
  setStatus: (status: number) => void;
  setUid: (status: number) => void;
  setEmail: (email: string) => void;
  setStartDate: (start_date: string) => void;
  setEndDate: (end_date: string) => void;
  findAccounts: any;
};

export function StudentsFilter({
  setType,
  setStatus,
  setUid,
  setEmail,
  findAccounts,
  setStartDate,
  setEndDate
}: Props) {

  const handChangeBeginDate = (e: any) => {
    setStartDate(moment(e[0]).format("YYYY-MM-DD 00:00:00"));
  }

  const handChangeEndDate = (e: any) => {
    setEndDate(moment(e[0]).format("YYYY-MM-DD 23:59:59"));
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-600px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Bộ lọc tài khoản</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className="row" id="kt_content">
          <div className='mb-5 col-lg-6'>
            <label className='form-label fw-bold'>Tên tài khoản / Mobile:</label>

            <div className='flex-grow-1'>
              <input
              placeholder='Nhâp tự khoá'
              className='form-control form-control-lg form-control-solid'
              type='text'
              name='search'
              autoComplete='off'
              onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className='mb-5 col-lg-6'>
            <label className='form-label fw-bold'>ID tài khoản:</label>

            <div className='flex-grow-1'>
              <input
              placeholder='Nhâp id tài khoản (Dạng số)'
              className='form-control form-control-lg form-control-solid'
              type='text'
              name='uid'
              autoComplete='off'
              onChange={(e) => setUid(parseInt(e.target.value))}
              />
            </div>
          </div>
        </div>

        <div className="row" id="kt_content">
          {/* begin::Form group */}
          <div className='fv-row mb-5 col-lg-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>Ngày bắt đầu khởi tao</label>
            <Flatpickr
              onChange={handChangeBeginDate}
              className='form-control form-control-solid'
              placeholder='Chọn ngày bắt đầu khởi tạo'
            />
          </div>
          <div className='fv-row mb-5 col-lg-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>Ngày cuối cùng khởi tao</label>
            <Flatpickr
              onChange={handChangeEndDate}
              className='form-control form-control-solid'
              placeholder='Chọn ngày cuối cùng khởi tạo'
            />
          </div>
          {/* end::Form group */}
        </div>

        <div className='mb-10'>
          <label className='form-label fw-bold'>Trạng thái:</label>

          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={'0'}
              onChange={e => setStatus(parseInt(e.target.value))}
            >
              <option value='0'>Toàn bộ trạng thái</option>
              <option value='1'>Hoạt động</option>
              <option value='4'>Đã bị khoá</option>
            </select>
          </div>
        </div>

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-white btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Quay lại
          </button>

          <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true' onClick={() => findAccounts()}>
            Tìm kiếm
          </button>
        </div>
      </div>
    </div>
  )
}
