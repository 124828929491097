import axios from 'axios'
import {TeachersModel} from '../models/TeachersModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const LIST_ACCOUNT = `${API_URL}/api/v1/user/list`
export const LIST_AUDIT_LOG = `${API_URL}/api/v1/system/audit-log/list`
export const CREATE_ACCOUNT = `${API_URL}/api/v1/auth/register`
export const GET_ACCOUNT = `${API_URL}/api/v1/user/show/:id`
export const UPDATE_ACCOUNT = `${API_URL}/api/v1/user/update`
export const DELETE_ACCOUNT = `${API_URL}/api/v1/user/delete/`
export const CHANEG_PASSWORD_ACCOUNT = `${API_URL}/api/v1/user/update-password`

const getWithPage = (limit: any, page: any, sort_column: any, sort_type: any, email: any, status: any, type: any, typeSearch: any) => {
  if(typeSearch){
    let filter = {
      limit, 
      page, 
      sort_column, 
      sort_type,
      type: "teacher",
      search: email,
      status,
      relationships: 'roles'
    }
    return axios.get<TeachersModel>(LIST_ACCOUNT + '?' + new URLSearchParams(filter).toString());
  } else {
    let filter = {
      limit, 
      page, 
      sort_column, 
      sort_type,
      type: "teacher",
      relationships: 'roles'
    }
    return axios.get<TeachersModel>(LIST_ACCOUNT + '?' + new URLSearchParams(filter).toString());
  }
}

const getAuditLogs = (limit: any, page: any, sort_column: any, sort_type: any) => {
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type
  }
  return axios.get<TeachersModel>(LIST_AUDIT_LOG + '?' + new URLSearchParams(filter).toString());
}

const deleteWithId = (account: any) => {
  return axios.delete<TeachersModel>(DELETE_ACCOUNT + account?.id);
}

const createAccount = (account: any) => {
  return axios.post<any>(CREATE_ACCOUNT, {
    email: account.email,
    name: account.name,
    password: account.password,
    type: "teacher",
    phone_number: account.phone_number,
    roles: [{
      id: parseInt(account.roles)
    }],
    banking_name: account.banking_name,
    banking_number: account.banking_number
  });
}

const updateContactById = (account: any) => {
  account.status = parseInt(account?.status);
  account.roles  = [{
    id: parseInt(account.roles)
  }]
  if(account.center_id === 0){
    delete account.center_id;
  }
  return axios.put<TeachersModel>(UPDATE_ACCOUNT, account);
}

const getAccountById = (id: number) => {
  return axios.get<TeachersModel>(GET_ACCOUNT + id);
}

const updatePasswordById = (account: TeachersModel) => {
  return axios.put<TeachersModel>(CHANEG_PASSWORD_ACCOUNT, account);
}

const AccountsService = {
    getWithPage,
    getAuditLogs,
    deleteWithId,
    createAccount,
    getAccountById,
    updateContactById,
    updatePasswordById
  };
  
export default AccountsService;