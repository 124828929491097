import React from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'
import {useCanAccess, useUser} from "../../../helpers";

export function MenuInner() {
    const intl = useIntl()
    const accessDiscount = useCanAccess('ProductDiscount', 'GetAll');
    const accessProduct = useCanAccess('Product', 'GetAll');
    const accessTeachers = useCanAccess('User', 'GetAll');
    const accessStudents = useCanAccess('User', 'GetAll');
    const accessSchedules = useCanAccess('LearningClassEnrollments', 'GetAll');
    const accessClasses = useCanAccess('LearningClass', 'GetAll');
    const accessCenters = useCanAccess('LearningCenter', 'GetAll');
    const accessRooms = useCanAccess('LearningRoom', 'GetAll');
    const accessAccounts = useCanAccess('User', 'GetAll');
    const accessVendors = useCanAccess('Vendor', 'GetAll');
    const accessProductTypes = useCanAccess('ProductType', 'GetAll');
    const accessNotifications = useCanAccess('NotificationSetting', 'GetAll');
    const accessVariantGroups = useCanAccess('VariantGroup', 'GetAll');
    const accessVariants = useCanAccess('Variant', 'GetAll');
    const accessRole = useCanAccess('System', 'GetAllRole');
    const auth = useUser();

  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      <MenuItem title='Cài đặt giao diện' to='/builder' />
      {/* <MenuInnerWithSub
        title='Crafted'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      > */}
        {/* PAGES */}
        {/* <MenuInnerWithSub
          title='Pages'
          to='/crafted/pages'
          icon='/media/icons/duotune/general/gen022.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Wizards'
            to='/crafted/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub> */}

        {/* ACCOUNT */}
        {/* <MenuInnerWithSub
          title='Accounts'
          to='/crafted/accounts'
          icon='/media/icons/duotune/communication/com006.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </MenuInnerWithSub> */}

        {/* ERRORS */}
        {/* <MenuInnerWithSub
          title='Errors'
          to='/error'
          icon='/media/icons/duotune/technology/teh004.svg'
          hasArrow={true}
          menuPlacement='bottom-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub> */}

        {/* Widgets */}
        {/* <MenuInnerWithSub
          title='Widgets'
          to='/crafted/widgets'
          icon='/media/icons/duotune/general/gen025.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </MenuInnerWithSub> */}
      {/* </MenuInnerWithSub> */}
      {(accessProduct || accessTeachers || accessStudents) && (auth.type === "administrator" || auth.type === "academic_affair") &&
          (<MenuInnerWithSub title='Quản lý tài nguyên' to='/assets' menuPlacement='bottom-start' menuTrigger='click'>
        {/* PAGES */}
          {accessProduct && (auth.type === "administrator" || auth.type === "academic_affair") && (<MenuItem to='/assets/products' title='Quản lý khoá học' hasBullet={false} icon='/media/icons/duotune/abstract/abs046.svg'/>)}
          {accessTeachers && (<MenuItem to='/assets/teachers' title='Quản lý giáo viên' hasBullet={false} icon='/media/icons/duotune/technology/teh005.svg'/>)}
          {accessStudents && (<MenuItem to='/assets/students' title='Quản lý học viên' hasBullet={false} icon='/media/icons/duotune/technology/teh002.svg'/>)}
      </MenuInnerWithSub>)}
      {(accessSchedules || accessClasses) && (
      <MenuInnerWithSub title='Quản lý tiến trình học' to='/learning' menuPlacement='bottom-start' menuTrigger='click'>
        {/* PAGES */}
          {accessSchedules && (<MenuItem to='/learning/schedules' title='Quản lý lịch học' hasBullet={false} icon='/media/icons/duotune/general/gen014.svg'/>)}
          {accessClasses && (<MenuItem to='/learning/classes' title='Quản lý lớp học' hasBullet={false} icon='/media/icons/duotune/abstract/abs046.svg'/>)}
      </MenuInnerWithSub>)}

      {(auth.type === "administrator" || auth.type === "academic_affair") && (
      <MenuInnerWithSub title='Quản lý sự kiện' to='/learning' menuPlacement='bottom-start' menuTrigger='click'>
        {/* PAGES */}
          <MenuItem to='/competition/manage' title='Danh sách sự kiện' icon='/media/icons/duotune/files/fil001.svg'/>
          <MenuItem to='/competition/topic' title='Quản lý bộ môn' icon='/media/icons/duotune/layouts/lay006.svg'/>
          <MenuItem to='/competition/type' title='Quản lý nhóm thí sinh' icon='/media/icons/duotune/communication/com005.svg'/>
          <MenuItem to='/competition/round' title='Quản lý vòng thi' icon='/media/icons/duotune/abstract/abs021.svg'/>
          <MenuItem to='/competition/examinee' title='Quản lý thí sinh tham gia' icon='/media/icons/duotune/communication/com006.svg'/>
      </MenuInnerWithSub>)}

      {(accessDiscount || accessCenters || accessRooms || accessAccounts || accessVendors || accessVariants &&
        accessProductTypes && accessNotifications && accessVariantGroups && accessRole) && (
      <MenuInnerWithSub title='Cài đặt hệ thống' to='/settings' menuPlacement='bottom-start' menuTrigger='click'>
        {/* PAGES */}
        {accessDiscount && (<MenuItem to='/settings/product/discount' title='Tỉ lệ discount' hasBullet={false} icon='/media/icons/duotune/ecommerce/ecm003.svg'/>)}
        {accessCenters && (<MenuItem to='/settings/learning/centers' title='Danh sách cơ sở' hasBullet={false} icon='/media/icons/duotune/ecommerce/ecm008.svg'/>)}
        {accessRooms && (<MenuItem to='/settings/learning/rooms' title='Danh sách phòng học' hasBullet={false} icon='/media/icons/duotune/general/gen029.svg'/>)}
        {accessAccounts && (<MenuItem to='/settings/accounts' title='Danh sách tài khoản' hasBullet={false} icon='/media/icons/duotune/communication/com005.svg'/>)}
        {accessVendors && (<MenuItem to='/settings/product/vendors' title='Phương pháp giảng dạy' hasBullet={false} icon='/media/icons/duotune/general/gen020.svg'/>)}
        {accessProductTypes && (<MenuItem to='/settings/product/product-types' title='Quản lý loại khóa học' hasBullet={false} icon='/media/icons/duotune/coding/cod010.svg'/>)}
        {accessNotifications && (<MenuItem to='/settings/notifications' title='Quản lý notification' hasBullet={false} icon='/media/icons/duotune/electronics/elc007.svg'/>)}
        {accessVariantGroups && (<MenuItem to='/settings/product/variant-groups' title='Quản lý nhóm biến thể' hasBullet={false} icon='/media/icons/duotune/ecommerce/ecm008.svg'/>)}
        {accessVariants && (<MenuItem to='/settings/product/variants' title='Quản lý biến thể' hasBullet={false} icon='/media/icons/duotune/general/gen025.svg'/>)}
        {accessRole && (<MenuItem to='/settings/roles' title='Quản lý vai trò' hasBullet={false} icon='/media/icons/duotune/coding/cod007.svg'/>)}
      </MenuInnerWithSub>)}
      {/* <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
