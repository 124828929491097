import { getPaginationItems } from '../lib/pagination';
import PageLink from './PageLink';

export type Props = {
  currentPage: number;
  lastPage: number;
  maxLength: number;
  setCurrentPage: (page: number) => void;
  limit: number;
  totalRecord: number;
};

export default function Pagination({
  currentPage,
  lastPage,
  maxLength,
  setCurrentPage,
  limit,
  totalRecord,
}: Props) {
  const pageNums = getPaginationItems(currentPage, lastPage, maxLength);

  return (
    <div className='d-flex flex-stack flex-wrap'>
        <div className='fs-6 fw-bold text-gray-700'>Showing {(limit*(currentPage-1) === 0)?1:limit*(currentPage-1)} to {limit*currentPage}  of {totalRecord} entries</div>
        <ul className='pagination'>
            <PageLink
                classLi='previous'
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
            >
                <i className='previous'></i>
            </PageLink>
            {pageNums.map((pageNum, idx) => (
                <PageLink
                    classLi=''
                    key={idx}
                    active={currentPage === pageNum}
                    disabled={isNaN(pageNum)}
                    onClick={() => setCurrentPage(pageNum)}
                >
                {!isNaN(pageNum) ? pageNum : '...'}
                </PageLink>
            ))}
            <PageLink
                classLi='next'
                disabled={currentPage === lastPage}
                onClick={() => setCurrentPage(currentPage + 1)}
            >
                <i className='next'></i>
            </PageLink>
        </ul>
    </div>
  );
}