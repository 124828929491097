import { RoleModel } from '../models/RoleModel';
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const LIST_ROLE = `${API_URL}/api/v1/system/role/list`
export const CREATE_ROLE = `${API_URL}/api/v1/system/role/create`
export const GET_ROLE = `${API_URL}/api/v1/system/role/show/`
export const UPDATE_ROLE = `${API_URL}/api/v1/system/role/update`
export const DELETE_ROLE = `${API_URL}/api/v1/system/role/delete/`

const getWithPage = (limit: any, page: any, sort_column: any, sort_type: any, relationships: any) => {
  let filter = {
    limit, 
    page, 
    sort_column,
    sort_type,
    relationships
  }
  return axios.get<RoleModel>(LIST_ROLE + '?' + new URLSearchParams(filter).toString());
}

const getAllRole = () => {
  let filter = {
    limit: "1000"
  }
  return axios.get<RoleModel>(LIST_ROLE + '?' + new URLSearchParams(filter).toString());
}

const deleteWithId = (role: any) => {
  return axios.delete<RoleModel>(DELETE_ROLE + role?.id, role);
}

const createRole = (role: RoleModel) => {
  let items: any[] = [];
  role.permissions.map((obj: any) => {
    items.push({...obj, id: parseInt(obj.id)});
  });
  role.permissions = items;
  return axios.post<RoleModel>(CREATE_ROLE, role);
}

const getRoleById = (role_id: number) => {
  return axios.get<RoleModel>(GET_ROLE + role_id);
}

const updateRoleById = (role: RoleModel) => {
  let items: any[] = [];
  role.permissions.map((obj: any) => {
    items.push({...obj, id: parseInt(obj.id)});
  });
  role.permissions = items;
  console.log(role);
  return axios.put<RoleModel>(UPDATE_ROLE, role);
}

const RoleService = {
    getWithPage,
    deleteWithId,
    createRole,
    getRoleById,
    updateRoleById,
    getAllRole
  };
  
export default RoleService;