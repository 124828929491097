/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from "moment"

type Props = {
  className: string,
  learningSchedules: any
}

const ListsWidget3: React.FC<Props> = ({className, learningSchedules}) => {
  type DayMapping = {
    [key: string]: string;
  };

  function convertDayToVietnamese(day: string): string {
    const daysMap: DayMapping = {
      Monday    : 'Thứ Hai',
      Tuesday   : 'Thứ Ba',
      Wednesday : 'Thứ Tư',
      Thursday  : 'Thứ Năm',
      Friday    : 'Thứ Sáu',
      Saturday  : 'Thứ Bảy',
      Sunday    : 'Chủ Nhật',
    };

    return daysMap[day] || day;
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Lịch học trong tuần này</h3>
        <div className='card-toolbar'>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {learningSchedules.map((learningSchedule: any, index: any) => (
          <div key={learningSchedule.schedule_id} className='d-flex align-items-center mb-8'>
            {/* begin::Bullet */}
            <span className='bullet bullet-vertical h-40px bg-success me-2'></span>
            {/* end::Bullet */}

            {/* begin::Description */}
            <div className='flex-grow-1'>
              <a href='#' className='text-gray-800 text-hover-primary fw-bolder fs-6'>
                {learningSchedule?.class?.class_name}
              </a>
              <span className='text-muted fw-bold d-block'>{learningSchedule?.schedule_hour}:{learningSchedule?.schedule_minute} <br/> {convertDayToVietnamese(learningSchedule.schedule_date_text)}, ngày {(moment(learningSchedule?.schedule_date).isValid())?moment(learningSchedule?.schedule_date).format("DD/MM/YYYY"):"-"}</span>
              <span className='text-muted fw-bold d-block'>{learningSchedule?.center.center_name}</span>
            </div>
            {/* end::Description */}
            <span className='badge badge-light-success fs-8 fw-bolder'>New</span>
          </div>
        ))}
        
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget3}
