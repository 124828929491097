import axios from 'axios'
import {EnrollmentsModel} from '../models/EnrollmentsModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const LIST_ENROLLMENT = `${API_URL}/api/v1/learning/class-enrollment/list`
export const LIST_CLASS_BY_ENROLLMENT = `${API_URL}/api/v1/learning/class-enrollment/getListClassForStudent`
export const CREATE_ENROLLMENT = `${API_URL}/api/v1/learning/class-enrollment/create`
export const GET_ENROLLMENT = `${API_URL}/api/v1/learning/class-enrollment/show/:id`
export const UPDATE_ENROLLMENT = `${API_URL}/api/v1/learning/class-enrollment/update`
export const DELETE_ENROLLMENT = `${API_URL}/api/v1/learning/class-enrollment/`
export const EXPORT_ENROLLMENT = `${API_URL}/api/v1/learning/class-enrollment/export`

const get = (limit: any, page: any, sort_column: any, sort_type: any, user_id: any) => {
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type,
    user_id: user_id
  }
  let query = new URLSearchParams(filter).toString();
  ["class"].forEach((item, index) => {
    query += `&relationships=${item}`
  })
  return axios.get<EnrollmentsModel>(LIST_ENROLLMENT + '?' + query);
}

const getClassForStudent = (limit: any, page: any, sort_column: any, sort_type: any, user_id: any) => {
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type,
    user_id: user_id
  }
  let query = new URLSearchParams(filter).toString();
  ["class"].forEach((item, index) => {
    query += `&relationships=${item}`
  })
  return axios.get<EnrollmentsModel>(LIST_CLASS_BY_ENROLLMENT + '?' + query);
}

const getUserActiveByClass = (limit: any, page: any, sort_column: any, sort_type: any, class_id: any) => {
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type,
    class_id: class_id,
  }
  let query = new URLSearchParams(filter).toString();
  ["user"].forEach((item, index) => {
    query += `&relationships=${item}`
  })
  return axios.get<EnrollmentsModel>(LIST_ENROLLMENT + '?' + query);
}

const getUserActiveForAttendance = (limit: any, page: any, sort_column: any, sort_type: any, class_id: any) => {
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type,
    class_id: class_id,
    status: '1'
  }
  let query = new URLSearchParams(filter).toString();
  ["user"].forEach((item, index) => {
    query += `&relationships=${item}`
  })
  return axios.get<EnrollmentsModel>(LIST_ENROLLMENT + '?' + query);
}

const remove = (enrollment: any) => {
  return axios.delete<EnrollmentsModel>(DELETE_ENROLLMENT + enrollment?.enrollment_id);
}

const create = (enrollment: any) => {
  enrollment.total_lesson_learned = parseInt(enrollment.total_lesson_learned);
  return axios.post<any>(CREATE_ENROLLMENT, enrollment);
}

const update = (enrollment: any) => {
  enrollment.total_lesson_learned = parseInt(enrollment.total_lesson_learned);
  return axios.put<EnrollmentsModel>(UPDATE_ENROLLMENT, enrollment);
}

const getExport = (startDate: any, endDate: any) => {
  let filter = {
    limit: '100',
    page: '1'
  }
  let query = new URLSearchParams(filter).toString();
  [startDate, endDate].forEach((item, index) => {
    query += `&created_at=${item}`
  })
  return axios.get<ArrayBuffer>(EXPORT_ENROLLMENT + '?' + query, {responseType : 'arraybuffer', headers : {'Content-Type': 'application/octet-stream'}});
}

const EnrollmentsService = {
    get,
    getClassForStudent,
    getUserActiveByClass,
    getUserActiveForAttendance,
    remove,
    create,
    update,
  };
  
export default EnrollmentsService;