import axios from 'axios'
import {NotificationModel} from '../models/NotificationModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const LIST_NOTIFICATION = `${API_URL}/api/v1/notifications`
export const CREATE_NOTIFICATION = `${API_URL}/api/v1/notifications`
export const GET_NOTIFICATION = `${API_URL}/api/v1/notifications/`
export const UPDATE_NOTIFICATION = `${API_URL}/api/v1/notifications`
export const DELETE_NOTIFICATION = `${API_URL}/api/v1/notifications/`
export const READ_ALL_NOTIFICATION = `${API_URL}/api/v1/notifications/`

const getWithPage = (limit: any, page: any, sort_column: any, sort_type: any, user_id: any, is_read: any, typeSearch: any) => {
    if(typeSearch){
        let filter = {
            limit,
            page,
            sort_column,
            sort_type,
            user_id,
            is_read
        }
        return axios.get<NotificationModel>(LIST_NOTIFICATION + '?' + new URLSearchParams(filter).toString());
    } else {
        let filter = {
            limit,
            page,
            sort_column,
            sort_type
        }
        return axios.get<NotificationModel>(LIST_NOTIFICATION + '?' + new URLSearchParams(filter).toString());
    }
}

const deleteWithId = (notification: any) => {
  return axios.delete<NotificationModel>(DELETE_NOTIFICATION + notification?.notification_id, notification);
}

const createNotificationSetting = (notification: NotificationModel) => {
  return axios.post<NotificationModel>(CREATE_NOTIFICATION, notification);
}

const getNotificationSettingById = (notification_id: number) => {
  return axios.get<NotificationModel>(GET_NOTIFICATION + notification_id);
}

const updateNotificationSettingById = (notification: NotificationModel) => {
  return axios.put<NotificationModel>(UPDATE_NOTIFICATION, {
    notification_id: notification.notification_id,
    is_send: notification.is_read,
  });
}

const readAllNotificationById = (user_id: any) => {
    return axios.put<NotificationModel>(READ_ALL_NOTIFICATION + user_id + "/read-all", user_id);
}

const NotificationService = {
    getWithPage,
    deleteWithId,
    createNotificationSetting,
    getNotificationSettingById,
    updateNotificationSettingById,
    readAllNotificationById
  };
  
export default NotificationService;