import axios from 'axios'
import {AccountsModel, IChangePassword} from '../models/AccountsModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const LIST_ACCOUNT = `${API_URL}/api/v1/user/list`
export const LIST_AUDIT_LOG = `${API_URL}/api/v1/system/audit-log/list`
export const CREATE_ACCOUNT = `${API_URL}/api/v1/auth/register`
export const GET_ACCOUNT = `${API_URL}/api/v1/user/show/`
export const UPDATE_ACCOUNT = `${API_URL}/api/v1/user/update`
export const DELETE_ACCOUNT = `${API_URL}/api/v1/user/delete/`
export const CHANEG_PASSWORD_ACCOUNT = `${API_URL}/api/v1/user/update-password`
export const CHANGE_PASSWORD = `${API_URL}/api/v1/user/change-password`

const getAllTeacher = () => {
  let filter = {
    limit: '1000', 
    page: '1', 
    sort_column: 'id', 
    sort_type: 'DESC',
    type: 'teacher'
  }
  return axios.get<AccountsModel>(LIST_ACCOUNT + '?' + new URLSearchParams(filter).toString());
}

const getAllAcademicAffair = () => {
  let filter = {
    limit: '1000', 
    page: '1', 
    sort_column: 'id', 
    sort_type: 'DESC',
    type: 'academic_affair'
  }
  return axios.get<AccountsModel>(LIST_ACCOUNT + '?' + new URLSearchParams(filter).toString());
}

const getWithPage = (limit: any, page: any, sort_column: any, sort_type: any, email: any, status: any, type: any, typeSearch: any) => {
  if(typeSearch){
    let filter = {
      limit, 
      page, 
      sort_column, 
      sort_type,
      type,
      search: email,
      status,
      relationships: 'roles'
    }
    return axios.get<AccountsModel>(LIST_ACCOUNT + '?' + new URLSearchParams(filter).toString());
  } else {
    let filter = {
      limit, 
      page, 
      sort_column, 
      sort_type,
      relationships: 'roles'
    }
    return axios.get<AccountsModel>(LIST_ACCOUNT + '?' + new URLSearchParams(filter).toString());
  }
}

const getAllAccount = () => {
  let filter = {
    limit: "1000"
  }
  return axios.get<AccountsModel>(LIST_ACCOUNT + '?' + new URLSearchParams(filter).toString());

}

const getAuditLogs = (limit: any, page: any, sort_column: any, sort_type: any) => {
  let filter = {
    limit, 
    page, 
    sort_column, 
    sort_type
  }
  return axios.get<AccountsModel>(LIST_AUDIT_LOG + '?' + new URLSearchParams(filter).toString());
}

const deleteWithId = (account: any) => {
  return axios.delete<AccountsModel>(DELETE_ACCOUNT + account?.id);
}

const createAccount = (account: any) => {
  return axios.post<any>(CREATE_ACCOUNT, {
    email: account.email,
    name: account.name,
    password: account.password,
    type: "academic_affair",
    phone_number: account.phone_number,
    roles: [{
      id: parseInt(account.roles)
    }],
    center_id: parseInt(account?.center_id)
  });
}

const updateContactById = (account: any) => {
  account.status = parseInt(account?.status);
  account.roles  = [{
    id: parseInt(account.roles)
  }]
  account.center_id = parseInt(account?.center_id)
  return axios.put<AccountsModel>(UPDATE_ACCOUNT, account);
}

const getAccountById = (id: number) => {
  return axios.get<AccountsModel>(GET_ACCOUNT + id);
}

const updatePasswordById = (account: AccountsModel) => {
  return axios.put<AccountsModel>(CHANEG_PASSWORD_ACCOUNT, account);
}

const changePassword = (account: any) => {
  console.log(account);
  return axios.post<any>(CHANGE_PASSWORD, {
    email: account.email,
    password: account.password,
    new_password: account.new_password,
    confirm_password: account.confirm_password,
  });
}

const AccountsService = {
    getWithPage,
    getAuditLogs,
    deleteWithId,
    createAccount,
    getAccountById,
    updateContactById,
    updatePasswordById,
    getAllAccount,
    changePassword,
    getAllTeacher,
    getAllAcademicAffair,
  };
  
export default AccountsService;