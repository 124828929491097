import axios from 'axios'
import {DiscountModel} from '../models/DiscountModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const LIST_DISCOUNT = `${API_URL}/api/v1/discounts`
export const CREATE_DISCOUNT = `${API_URL}/api/v1/discounts`
export const GET_DISCOUNT = `${API_URL}/api/v1/discounts/`
export const UPDATE_DISCOUNT = `${API_URL}/api/v1/discounts`
export const DELETE_DISCOUNT = `${API_URL}/api/v1/discounts/`

const getWithPage = () => {
  return axios.get<DiscountModel>(LIST_DISCOUNT + '?page=1&limit=100&sort_column=discount_id&sort_type=DESC');
}

const deleteWithId = (discount: any) => {
  return axios.delete<DiscountModel>(DELETE_DISCOUNT + discount?.discount_id, discount);
}

const createDiscount = (discount: DiscountModel) => {
  return axios.post<DiscountModel>(CREATE_DISCOUNT, discount);
}

const getDiscountById = (discount_id: number) => {
  return axios.get<DiscountModel>(GET_DISCOUNT + discount_id);
}

const updateDiscountById = (discount: DiscountModel) => {
  return axios.put<DiscountModel>(UPDATE_DISCOUNT, {
    discount_id: discount.discount_id,
    discount_percent: discount.discount_percent
  });
}

const DiscountService = {
  getWithPage,
  deleteWithId,
  createDiscount,
  getDiscountById,
  updateDiscountById,
};
  
export default DiscountService;