import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks} from '../../../partials'
import {color} from "chart.js/helpers";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {UserModel} from "../../../../app/modules/auth/models/UserModel";
import {NotificationModel} from "../../../../app/modules/settings/models/NotificationModel";
import NotificationService from "../../../../app/modules/settings/redux/NotificationCRUD";
// import { socket } from '../../../../socket';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

interface Props{
}

const Topbar: FC<Props> = ({}) => {
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [notifications, setNotifications] = useState<Array<NotificationModel>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isReload, setIsReload] = useState(false);
  const [limit, setLimit] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [user_id, setUserId] = useState(user.data.id);
  const is_read = 2;
  const [totalRecord, setTotalRecord] = useState(0);
  // const [isConnected, setIsConnected] = useState(socket.connected);
  // const [fooEvents, setFooEvents] = useState([]);

  useEffect(() => {
    setUserId(user.data.id)
    retrieveNotification();

    // set socket
    // function onSingleEvent(value: any) {
    //   retrieveNotification(); 
    // }
    // socket.on('single', onSingleEvent);
  }, [currentPage, isReload]);

  const retrieveNotification = () => {
    NotificationService.getWithPage(limit, currentPage, 'notification_id', 'DESC', user_id, is_read, true)
        .then((response: any) => {
          setNotifications(response.data.data.rows);
          setTotalRecord(response.data.data.total_rows);
          setLastPage(response.data.data.total_pages);
        })
        .catch((e: Error) => {
          // console.log(e);
        });
  };

  const readAllNotification = () => {
    NotificationService.readAllNotificationById(user_id)
        .then((response: any) => {
          setTotalRecord(0);
        })
        .catch(() => {

        })
  };
  return (
      <>
      <style>{`
        .badge-custom {
          padding: 3px 5px;
          border-radius: 10px;
          right: -2px;
          top: -2px !important;
        }
      `}</style>
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* Search */}
        {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
        {/* Activities */}
        {/* <div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}> */}
          {/* begin::Drawer toggle */}
          {/* <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_activities_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen032.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div> */}
          {/* end::Drawer toggle */}
        {/* </div> */}
        {/* Quick links */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
          {/* begin::Menu wrapper */}
          {/* <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen025.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <QuickLinks /> */}
          {/* end::Menu wrapper */}
        {/* </div> */}

        {/* CHAT */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
          {/* begin::Menu wrapper */}
          {/* <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_drawer_chat_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/communication/com012.svg'
              className={toolbarButtonIconSizeClass}
            />

            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
          </div> */}
          {/* end::Menu wrapper */}
        {/* </div> */}

        {/* NOTIFICATIONS */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu- wrapper */}
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative label pulse pulse-danger mr-10',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            onClick={() => readAllNotification()}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen022.svg'
              className={toolbarButtonIconSizeClass}
            />
            {totalRecord ? (
            <span className="badge badge-danger position-absolute badge-custom">{totalRecord}</span>
            ):("")}
          </div>
          <HeaderNotificationsMenu />
          {/* end::Menu wrapper */}
        </div>

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-25px w-25px rounded'
              src={toAbsoluteUrl('/media/avatars/150-25.jpg')}
              alt='metronic'
            />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
        </>
  )
}

export {Topbar}
