/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, ChangeEvent } from 'react'
import {KTSVG, TOAST, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLocation} from 'react-router'
import {StudentsModel} from './models/StudentsModel'
import AccountsService from './redux/StudentsCRUD'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {StudentsFilter} from './components/StudentsFilter'
import Pagination from '../../components/Pagination'
import {useDownloadFile} from '../../lib/useDownloadFile'
import moment from "moment";
import StudentsModal from '../../../_metronic/partials/modals/students/StudentsModal';
import StudentDetailModal from '../../../_metronic/partials/modals/students/StudentDetailModal';
import { useParams } from "react-router-dom";
import { RoleModel, RolePermissionModel } from '../settings/models/RoleModel';
import RoleService from '../settings/redux/RoleCRUD';
import { LearningClassModel } from '../learnings/models/LearningClassModel'
import LearningClassService from '../learnings/redux/LearningClassCRUD'
import DiscountService from '../settings/redux/DiscountCRUD'
import {DiscountModel} from '../settings/models/DiscountModel'
import {useUser} from '../../../_metronic/helpers'
import AccountPasswordModal from '../../../_metronic/partials/modals/settings/AccountPasswordModal';

const StudentsList: React.FC = () => {
  const params = useParams<{id: string}>();
  const location = useLocation()
  const [accounts, setAccounts] = useState<Array<StudentsModel>>([]);
  const [show, setShow] = useState(false);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [toastMessage, setToastMessage] = useState("");
  const [toastClass, setToastClass] = useState("");
  const [accountDelete, setAccountDelete] = useState({});
  const [email, setEmail] = useState("");
  const [uid, setUid] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState(0);
  const [type, setType] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [roles, setRoles] = useState<Array<RoleModel>>([]);
  const [isReload, setIsReload] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    id: 0,
    name: '',
    password: '',
    email: '',
    phone_number: '',
    status: 1,
    roles: 0,
    birth_year: 0,
    birth_day: 0,
    birth_month: 0,
    reference_name: '',
    reference_phone: '',
    reference_email: '',
    source: '',
    address: ''
  });
  const [initialValuesEnrollments, setInitialValuesEnrollments] = useState({
    enrollment_id: 0,
    class_id: 0,
    user_id: 0,
    status: 1,
    total_lesson: 0,
    total_lesson_learned: 0,
    total_lesson_promote: 0,
    total_discount_percent: 0,
    discount_id: 0
  });
  const [isDisableClass, setIsDisableClass] = useState(false)
  const [learningClasses, setLearningClasses] = useState<Array<LearningClassModel>>([]);
  const [discounts, setDiscounts] = useState<Array<DiscountModel>>([]);
  const auth = useUser();
  
  useEffect(() => {
    retrieveAccounts();

    // SETUP CLASSES
    retrieveLearningClass();

    // SETUP DISCOUNTS
    setupDiscounts();
  }, [currentPage, isReload]);

  const getFileName = () => {
    return moment().format('YYYY-MMMM-D') + "_students-file.xlsx";
  };

  const onErrorDownloadFile = () => {
    // setButtonState(ButtonState.Primary);
    // setShowAlert(true);
    setTimeout(() => {
      console.log("ERROR");
    }, 3000);
  };

  const downloadSampleCsvFile = () => {
    return AccountsService.getExport(limit, currentPage, 'id', 'DESC', email, status, uid, startDate, endDate, type);
  };

  const { ref, url, download, name } = useDownloadFile({
    apiDefinition: downloadSampleCsvFile,
    onError: onErrorDownloadFile,
    getFileName,
  });

  const retrieveLearningClass = () => {
    LearningClassService.getAllClass(1000, 1, 'class_id', 'DESC', ['room', 'academic_affair', 'product_item'], auth)
      .then((response: any) => {
        let dataLearningClass: any[] = [];
          response.data.data.rows.map((learning: any) => {
            dataLearningClass.push({
              label: learning.class_name + " / " + learning.room.center.center_name + " - " + learning.room.room_number + " / Phụ trách: " + learning.academic_affair.name,
              value: learning.class_id,
              discount: learning.product_item.product.discount_ids,
              total: learning.product_item.total,
              center_id: learning.room.center.center_id
            })
          })

          setLearningClasses(dataLearningClass);
      })
      .catch((e: Error) => {
        // console.log(e);
      });
  };

  const setupDiscounts = () => {
    DiscountService.getWithPage()
      .then((response: any) => {
        let dataDiscounts: any[] = [];
        response.data.data.rows.map((discount: any) => {
          dataDiscounts.push({
            label: discount.discount_percent + "%",
            value: discount.discount_id,
            percent: discount.discount_percent
          })
        })
        setDiscounts(dataDiscounts);
      })
      .catch((e: Error) => {
        // console.log(e);
      });
  }

  const retrieveAccounts = () => {
    AccountsService.getWithPage(limit, currentPage, 'id', 'DESC', email, status, uid, startDate, endDate, type, false)
      .then((response: any) => {
        setAccounts(response.data.data.rows);
        setTotalRecord(response.data.data.total_rows);
        setLastPage(response.data.data.total_pages);

        // setup roles
        retrieveRoles();
      })
      .catch((e: Error) => {
        // console.log(e);
      });
  };

  const retrieveRoles = () => {
    RoleService.getWithPage(500, currentPage, 'id', 'DESC', "permissions")
      .then((response: any) => {
        setRoles(response.data.data.rows);
      })
      .catch((e: Error) => {
        // console.log(e);
      });
  }

  const findAccounts = () => {
    AccountsService.getWithPage(limit, currentPage, 'id', 'DESC', email, status, uid, startDate, endDate, type, true)
      .then((response: any) => {
        setAccounts(response.data.data.rows);
        setTotalRecord(response.data.data.total_rows);
        setLastPage(response.data.data.total_pages);
      })
      .catch((e: Error) => {
        // console.log(e);
      });
  };

  const handleShow = (account: StudentsModel) => {
    setAccountDelete(account);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  }

  const handleDeleteAccount = () => {
    setShow(false);
    deleteAccount(accountDelete);
  };

  const deleteElement = (account: any) => {
    setAccounts(accounts.filter(item => item?.id !== account?.id));
  };

  const deleteAccount = (accountDelete: any) => {
    // call server
    AccountsService.deleteWithId(accountDelete)
    .then((response: any) => {
      // Xoa Discount
      deleteElement(accountDelete);

      // notify
      notify("Bạn đã xoá thành công", "show");
    })
    .catch((e: Error) => {
      // notify
      notify("Có lỗi với hệ thống, mời bạn thử lại sau", "show");
    });
  };

  const notify = (message: any, toastClass: any) => {
    setToastMessage(message);
    setToastClass(toastClass);
    setTimeout(() => {
      setToastMessage("");
      setToastClass("");
    }, 2000)
  }

  const handleShowCreateModal = () => {
    setShowCreateModal(true);
    setInitialValues({
      id: 0,
      name: '',
      password: '',
      email: '',
      phone_number: '',
      status: 1,
      roles: 0,
      birth_year: 0,
      birth_day: 0,
      birth_month: 0,
      reference_name: '',
      reference_phone: '',
      reference_email: '',
      source: '',
      address: ''
    });
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  }

  const handleEdit = (account: any) => {
    if(account.roles !== null && account.roles.length > 0){
      let roles = account.roles;
      account.roles = roles[0].id;
    } else {
      if(account.roles === 0 || account.roles.length === 0){
        account.roles = 0;
      }
    }
    
    setInitialValues(account);
    setShowCreateModal(true);
  };

  const handleShowDetailModal = () => {
    setShowDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setShowDetailModal(false);
  }

  const handleDetail = (account: any) => {
    if(account.roles !== null && account.roles.length > 0){
      let roles = account.roles;
      account.roles = roles[0].id;
    } else {
      if(account.roles === 0 || account.roles.length === 0){
        account.roles = 0;
      }
    }

    setInitialValues(account);
    setShowDetailModal(true);

    setInitialValuesEnrollments({
      enrollment_id: 0,
      class_id: 0,
      user_id: 0,
      status: 1,
      total_lesson: 0,
      total_lesson_learned: 0,
      total_lesson_promote: 0,
      total_discount_percent: 0,
      discount_id: 0
    })

    setIsDisableClass(false);
  };

  const handleShowPasswordModal = () => {
    setShowPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
  }

  const handleEditPassword = (account: any) => {
    setInitialValues(account);
    setShowPasswordModal(true);
  };

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Danh sách tài khoản</span>
        </h3>

        <div className='card-toolbar m-0'>
          <button
            type='button'
            className='btn btn-sm btn-light-primary m-2'
            onClick={() => handleShowCreateModal()}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen041.svg'
              className='svg-icon-3 svg-icon-primary'
            /> Thêm mới tài khoản học viên
          </button>

          <a href={url} download={name} className="hidden" ref={ref} />
          <button
            type='button'
            className='btn btn-sm btn-light-primary m-2'
            onClick={download}
          >
            <KTSVG
              path='/media/icons/duotune/files/fil021.svg'
              className='svg-icon-3 svg-icon-primary'
            /> Export
          </button>
          
          <button
            type='button'
            className='btn btn-clean btn-sm btn-icon-primary btn-active-light-primary me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen024.svg'
              className='svg-icon-3 svg-icon-primary'
            /> Bộ lọc tìm kiếm
          </button>
          <StudentsFilter setStartDate={setStartDate} setEndDate={setEndDate} setEmail={setEmail} setStatus={setStatus} setUid={setUid} setType={setType} findAccounts={findAccounts} />
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-100px'>Tên tài khoản</th>
                <th className='min-w-100px'>Địa chỉ email</th>
                <th className='min-w-100px'>Người đại diện</th>
                <th className='min-w-100px'>Lần đăng nhập cuối</th>
                <th className='min-w-100px text-center'>Hành động</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {accounts?.map(account => (
              <tr key={account?.id}>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value={account.id} />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <li className="d-flex align-items-center py-2 pb-0">
                        <span className="bullet bullet-vertical bg-success me-5"></span>ID: {account.id}
                      </li>
                      <li className="d-flex align-items-center py-2 pb-0">
                        <span className="bullet bullet-vertical bg-danger me-5"></span>{account.name}
                      </li>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <a  className='text-dark text-hover-primary fs-6'>
                        {account.email}
                      </a>
                      {account.status === 1? (
                        <span className='text-success fw-bold d-block'><i className="las la-check text-success"></i> Hoạt động</span>
                      ):(
                        <span className='text-danger fw-bold d-block'><i className="las la-window-close text-danger"></i> Đang bị khoá</span>
                      )}

                      <div className='d-flex justify-content-start flex-column mt-5'>
                        <span className='text-default fw-bold d-block mb-2'>
                          <i className="las la-calendar text-default"></i> {(moment(account?.created_at).isValid())?moment(account?.created_at).format("DD/MM/YYYY kk:mm:ss"):"-"}
                        </span>
                        <span className='text-default fw-bold d-block mb-2'>
                          <i className="las la-map-signs text-default"></i> {account?.source?account?.source:"Nguồn chưa xác định"}
                        </span>
                        <span className='text-default fw-bold d-block'>
                          <i className="las la-tags text-default"></i> Học viên
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <a  className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                    {(account.reference_name)?account.reference_name:'Không xác định'}
                  </a>
                  <span className='text-muted fw-bold d-block'>{(account.reference_phone)?account.reference_phone:'Không xác định'}</span>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <a  className='text-dark text-hover-primary fs-6'>
                        {(moment(account?.last_time_login).isValid())?moment(account?.last_time_login).format("DD/MM/YYYY kk:mm:ss"):"-"}
                      </a>
                    </div>
                  </div>
                </td>
                <td className='text-end min-w-100px'>
                  <div className="d-flex flex-column">
                    <li className="d-flex align-items-center py-2">
                      <div className="menu-item px-3">
                        <a className="menu-link px-3 p-0" onClick={() => handleDetail(account)}>
                          <KTSVG path='/media/icons/duotune/general/gen039.svg' className='svg-icon-3 m-2 mt-0 mb-0' /> Danh sách lớp
                        </a>
                      </div>
                    </li>

                    <li className="d-flex align-items-center py-2">
                      <div className="menu-item px-3">
                        <a className="menu-link px-3 p-0" onClick={() => handleEdit(account)}>
                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 m-2 mt-0 mb-0' /> Chỉnh sửa
                        </a>
                      </div>
                    </li>

                    <li className="d-flex align-items-center py-2">
                      <div className="menu-item px-3">
                        <a className="menu-link px-3 p-0" onClick={() => handleEditPassword(account)}>
                          <KTSVG path='/media/icons/duotune/general/gen049.svg' className='svg-icon-3 m-2 mt-0 mb-0' /> Đổi mật khẩu
                        </a>
                      </div>
                    </li>

                    <li className="d-flex align-items-center py-2">
                      <div className="menu-item px-3">
                        <a className="menu-link px-3 p-0" onClick={() => handleShow(account)} data-kt-users-table-filter="delete_row">
                          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 m-2 mt-0 mb-0' /> Xoá
                        </a>
                      </div>
                    </li>
                  </div>

                  {/* <a href="#" className="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                    Hành động <i className="las la-angle-down"></i>
                  </a>
                  <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-250px py-4" data-kt-menu="true" >
                    <div className="menu-item px-3">
                      <a className="menu-link px-3" onClick={() => handleDetail(account)}>
                        <KTSVG path='/media/icons/duotune/general/gen039.svg' className='svg-icon-3 m-2 mt-0 mb-0' /> Danh sách lớp
                      </a>
                    </div>
                    <div className="menu-item px-3">
                      <a className="menu-link px-3" onClick={() => handleEdit(account)}>
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 m-2 mt-0 mb-0' /> Chỉnh sửa
                      </a>
                    </div>
                    <div className="menu-item px-3">
                      <a className="menu-link px-3" onClick={() => handleEditPassword(account)}>
                        <KTSVG path='/media/icons/duotune/general/gen049.svg' className='svg-icon-3 m-2 mt-0 mb-0' /> Đổi mật khẩu
                      </a>
                    </div>
                    <div className="menu-item px-3">
                      <a className="menu-link px-3" onClick={() => handleShow(account)} data-kt-users-table-filter="delete_row">
                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 m-2 mt-0 mb-0' /> Xoá
                      </a>
                    </div>
                  </div> */}
                </td>
              </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          
          <Pagination
            currentPage={currentPage}
            lastPage={lastPage}
            maxLength={7}
            setCurrentPage={setCurrentPage}
            limit={limit}
            totalRecord={totalRecord}
          />
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}

      {/* begin::Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Xác nhận</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bạn có chắc chắn muốn xóa?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Hủy bỏ
          </Button>
          <Button variant="primary" onClick={handleDeleteAccount}>
            Đồng ý
          </Button>
        </Modal.Footer>
      </Modal>
      <TOAST message={toastMessage} toastClass={toastClass} />
      {/* end::Modal */}

      <StudentsModal notify={notify} roles={roles} initialValues={initialValues} handleCloseCreateModal={handleCloseCreateModal} showCreateModal={showCreateModal} accounts={accounts} setAccounts={setAccounts} setIsReload={setIsReload} />
      <StudentDetailModal setIsDisableClass={setIsDisableClass} isDisableClass={isDisableClass} initialValuesEnrollments={initialValuesEnrollments} setInitialValuesEnrollments={setInitialValuesEnrollments} discounts={discounts} learningClasses={learningClasses} notify={notify} initialValues={initialValues} handleCloseDetailModal={handleCloseDetailModal} showDetailModal={showDetailModal} accounts={accounts} setAccounts={setAccounts} setIsReload={setIsReload} />
      <AccountPasswordModal notify={notify} initialValues={initialValues} handleClosePasswordModal={handleClosePasswordModal} showPasswordModal={showPasswordModal} accounts={accounts} setAccounts={setAccounts} setIsReload={setIsReload} />
    </div>
  )
}

export {StudentsList}
