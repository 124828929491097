import moment from 'moment';
import axios from 'axios'
import {LearningScheduleModel} from '../models/LearningScheduleModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const LIST_SCHEDULE = `${API_URL}/api/v1/learning/class-schedule`
export const CREATE_SCHEDULE = `${API_URL}/api/v1/learning/class-schedule`
export const GET_SCHEDULE = `${API_URL}/api/v1/learning/class-schedule/show/:id`
export const UPDATE_SCHEDULE = `${API_URL}/api/v1/learning/class-schedule`
export const DELETE_SCHEDULE = `${API_URL}/api/v1/learning/class-schedule/`
export const UPDATE_SCHEDULE_ATTENDANCE = `${API_URL}/api/v1/learning/class-schedule/is-attendance`
export const EXPORT_PAYROLL_TIME_SHEET = `${API_URL}/api/v1/payroll-timesheet/export`

const get = (limit: any, page: any, sort_column: any, sort_type: any, class_id: any, relationships: any, auth: any) => {
    let filter = {
        limit, 
        page, 
        sort_column, 
        sort_type,
        class_id
    }

    if(auth.type === "teacher"){
        Object.assign(filter, {teacher_id: auth.id})
    } else if(auth.type === "academic_affair") {
        Object.assign(filter, {academic_affair: auth.id})
    } else if (auth.type === "student"){
        Object.assign(filter, {student_id: auth.id})
    }

    let query = new URLSearchParams(filter).toString()
    if (Array.isArray(relationships)) {
        relationships.forEach((item, index) => {
            query += `&relationships=${item}`
        })
    }

    return axios.get<LearningScheduleModel>(LIST_SCHEDULE + '?' + query);
}

const getWithPage = (limit: any, page: any, sort_column: any, sort_type: any, relationships: any, scheduleDefaultDate:any, auth:any) => {
    let filter = {
        limit,
        page,
        sort_column,
        sort_type,
    }

    if(auth.type === "teacher"){
        Object.assign(filter, {teacher_id: auth.id})
    } else if(auth.type === "academic_affair") {
        Object.assign(filter, {academic_affair: auth.id})
    } else if (auth.type === "student"){
        Object.assign(filter, {student_id: auth.id})
    }

    let query = new URLSearchParams(filter).toString()
    if (Array.isArray(relationships)) {
        relationships.forEach((item, index) => {
            query += `&relationships=${item}`
        })
    }
    [scheduleDefaultDate.startDate, scheduleDefaultDate.endDate].forEach((item, index) => {
        query += `&schedule_date=${item}`
    })
    return axios.get<LearningScheduleModel>(LIST_SCHEDULE + '?' + query);
}

const getWithPageSearch = (limit: any, page: any,
                           sort_column: any, sort_type: any,
                           relationships: any, classTeacher: any,
                           classAcademicAffair: any, scheduleDate:any,
                           classLearningCenters: any,
                           classLearningRooms: any,
                           classLearningClass: any,
                           auth: any) => {

    let filter = {
        limit,
        page,
        sort_column,
        sort_type,
        teacher_id: classTeacher,
        academic_affair_id: classAcademicAffair,
        center_id: classLearningCenters,
        room_id: classLearningRooms,
        class_id: classLearningClass,
    }

    if(auth.type === "teacher"){
        Object.assign(filter, {teacher_id: auth.id})
    }  else if(auth.type === "academic_affair") {
        Object.assign(filter, {academic_affair_id: auth.id})
    } else if (auth.type === "student"){
        Object.assign(filter, {student_id: auth.id})
    }

    let query = new URLSearchParams(filter).toString()
    if (Array.isArray(relationships)) {
        relationships.forEach((item, index) => {
            query += `&relationships=${item}`
        })
    }

    if (scheduleDate != "" && typeof scheduleDate === 'object'){
        const scheduleDates = Object.values(scheduleDate);
        scheduleDates.forEach((item, index) => {
            if (item !== ''){
                query += `&schedule_date=${item}`
            }
        })
    }

    return axios.get<LearningScheduleModel>(LIST_SCHEDULE + '?' + query);
}


const getWithPageQuickSearch = (limit: any, page: any,
                           sort_column: any, sort_type: any,
                           relationships: any, scheduleDate:any,
                           auth: any) => {

    let filter = {
        limit,
        page,
        sort_column,
        sort_type,
    }

    if(auth.type === "teacher"){
        Object.assign(filter, {teacher_id: auth.id})
    }  else if(auth.type === "academic_affair") {
        Object.assign(filter, {academic_affair: auth.id})
    } else if (auth.type === "student"){
        Object.assign(filter, {student_id: auth.id})
    }

    let query = new URLSearchParams(filter).toString()
    if (Array.isArray(relationships)) {
        relationships.forEach((item, index) => {
            query += `&relationships=${item}`
        })
    }

    if (scheduleDate != "" && typeof scheduleDate === 'object'){
        const scheduleDates = Object.values(scheduleDate);
        scheduleDates.forEach((item, index) => {
            if (item !== ''){
                query += `&schedule_date=${item}`
            }
        })
    }

    return axios.get<LearningScheduleModel>(LIST_SCHEDULE + '?' + query);
}

const remove = (schedule: any) => {
  return axios.delete<LearningScheduleModel>(DELETE_SCHEDULE + schedule?.schedule_id);
}

const create = (schedule: any) => {
  return axios.post<any>(CREATE_SCHEDULE, schedule);
}

const update = (schedule: any) => {
  return axios.put<LearningScheduleModel>(UPDATE_SCHEDULE, schedule);
}

const updateIsAttendance = (schedule: any) => {
    return axios.put<LearningScheduleModel>(UPDATE_SCHEDULE_ATTENDANCE, schedule);
}

const getExport = (startDate: any, endDate: any) => {
    let filter = {
        limit: '100',
        page: '1'
    }
    let query = new URLSearchParams(filter).toString();
    [startDate + " 00:00:00", endDate + " 23:59:59"].forEach((item, index) => {
        query += `&created_at=${item}`
    })
    return axios.get<ArrayBuffer>(EXPORT_PAYROLL_TIME_SHEET + '?' + query, {responseType : 'arraybuffer', headers : {'Content-Type': 'application/octet-stream'}});
}

const LearningScheduleService = {
    get,
    getWithPage,
    getWithPageSearch,
    getWithPageQuickSearch,
    remove,
    create,
    update,
    updateIsAttendance,
    getExport
  };
  
export default LearningScheduleService;