/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget3,
  ListsWidget6,
} from '../../../_metronic/partials/widgets'
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {NotificationModel} from "../../modules/settings/models/NotificationModel";
import NotificationService from "../../modules/settings/redux/NotificationCRUD";
import {UserModel} from "../../modules/auth/models/UserModel";
import moment from 'moment'
import {LearningScheduleModel} from "../../modules/learnings/models/LearningScheduleModel";
import LearningScheduleService from "../../modules/learnings/redux/LearningScheduleCRUD";
import {useUser} from '../../../_metronic/helpers'

export type Props = {
  notifications: any;
  learningSchedules: any;
};

const DashboardPage: FC<Props> = ({
  notifications,
  learningSchedules
}: Props) => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' learningSchedules={learningSchedules}/>
      </div>
      <div className='col-xl-8'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' notifications={notifications} />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [notifications, setNotifications] = useState<Array<NotificationModel>>([]);
  const [user_id, setUserId] = useState(user.data.id);
  const [scheduleDate, setScheduleDate] = useState({
    startDate: moment().startOf('isoWeek').format("YYYY-MM-DD"),
    endDate: moment().endOf('isoWeek').format("YYYY-MM-DD")
  })
  const [learningSchedules, setLearningSchedules] = useState<Array<LearningScheduleModel>>([]);
  const auth = useUser();

  useEffect(() => {
    // get notifications
    retrieveNotification();

    // get latest schedule
    retrieveLearningSchedule();
  }, []);

  const retrieveNotification = () => {
    NotificationService.getWithPage(5, 1, 'notification_id', 'DESC', user_id, 0, true)
      .then((response: any) => {
        setNotifications(response.data.data.rows);
      })
      .catch((e: Error) => {
        // console.log(e);
      });
  };

  const retrieveLearningSchedule = () => {
    LearningScheduleService.getWithPageSearch(
      20, 
      1, 
      'schedule_date',
      'DESC',
        ['class','room','center','teacher','academic_affair'],
      0, 
      0,
      scheduleDate,
      0,
      0,
      0,
      auth
    )
    .then((response: any) => {
      setLearningSchedules(response.data.data.rows);
    })
    .catch((e: Error) => {
      // console.log(e);
    });
  };

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage notifications={notifications} learningSchedules={learningSchedules} />
    </>
  )
}

export {DashboardWrapper}
