import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../setup";

export const useCanAccess = (module:string, action:string) => {
    const user:any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
    let hasPermission = false;
    if (user) {
        //check role
        const roles: any = user.data.roles;
        let role = roles.find((role: any) => role.title === "Administrator");
        if (typeof role != "undefined") {
            hasPermission = true
        }
        //check permissions
        const permissions: any = user.data.permissions;
        if (module) {
            if (module in permissions) {
                if (permissions[module]) {
                    let checkAction = permissions[module].includes(action)
                    if (checkAction) {
                        hasPermission = true
                    }
                }
            }
        }
    }

    return hasPermission; // Trả về true nếu có quyền, ngược lại trả về false
};

// export function canAccess(module:string, action:string) {
//     const user:any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
//     if (user) {
//         const permissions: any = user.data.permissions;
//         const roles: any = user.data.roles;
//         //check role
//         let role = roles.find((role: any) => role.title === "Administrator");
//         if (typeof role != "undefined") {
//             return true
//         }
//         if (module) {
//             if (module in permissions) {
//                 if (permissions[module]) {
//                     let checkAction = permissions[module].includes(action)
//                     if (checkAction) {
//                         return true
//                     }
//                 }
//             }
//         }
//     }
//     return false
// }