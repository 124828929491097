import { HTMLProps } from 'react';
import cn from 'classnames';

export type Props = HTMLProps<HTMLAnchorElement> & { active?: boolean, classLi?: string };

export default function PageLink({
  classLi,
  className,
  active,
  disabled,
  children,
  ...otherProps
}: Props) {
  const customClassName = cn('page-link', className, {
    active,
    disabled,
  });

  if (disabled) {
    return <span className={`disabled`}>{children}</span>;
  }

  return (
    <li className={active ? 'page-item active ' + classLi : 'page-item ' + classLi}>
      <a
        href='#'
        className={customClassName}
        aria-current={active ? 'page' : undefined}
        {...otherProps}
      >
        {children}
      </a>
    </li>
  );
}