/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import moment from "moment"

type Props = {
  className: string,
  notifications: any
}

const ListsWidget6: React.FC<Props> = ({className, notifications}) => {
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Thông báo mới nhất</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-0'>
        {notifications.map((notification: any, index: any) => (
          <div key={notification.notification_id} className='d-flex align-items-center bg-light-primary rounded p-5 mb-7'>
            {/* begin::Icon */}
            <span className='svg-icon svg-icon-primary me-5'>
              <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
            </span>
            {/* end::Icon */}
            {/* begin::Title */}
            <div className='flex-grow-1 me-2'>
              <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                {notification.name}
              </a>
              <p>{notification.message}</p>
              <span className='text-muted fw-bold d-block'>{moment(notification.created_at).format("DD/MM/YYYY")}</span>
            </div>
            {/* end::Title */}
          </div>
        ))}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget6}
