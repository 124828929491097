import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import TeachersPage from '../modules/assets/TeachersPage'
import StudentsPage from '../modules/assets/StudentsPage'
import {useCanAccess} from "../../_metronic/helpers";
import ProtectedRoute from "./ProtectedRoute";


export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const DiscountPage = lazy(() => import('../modules/settings/DiscountPage'))
  const AccountsPage = lazy(() => import('../modules/settings/AccountsPage'))
  const ProductsPage = lazy(() => import('../modules/assets/ProductsPage'))
  const VendorsPage = lazy(() => import('../modules/settings/VendorsPage'))
  const LearningCenterPage = lazy(() => import('../modules/settings/LearningCenterPage'))
  const LearningRoomPage = lazy(() => import('../modules/settings/LearningRoomPage'))
  const VariantGroupPage = lazy(() => import('../modules/settings/VariantGroupPage'))
  const VariantPage = lazy(() => import('../modules/settings/VariantPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const NotificationSettingPage = lazy(() => import('../modules/settings/NotificationSettingPage'))
  const NotificationPage = lazy(() => import('../modules/settings/NotificationPage'))
  const RolePage = lazy(() => import('../modules/settings/RolePage'))
  const LearningClassPage = lazy(() => import('../modules/learnings/LearningClassPage'))
  const LearningSchedulePage = lazy(() => import('../modules/learnings/LearningSchedulePage'))
  const ProductTypesPage = lazy(() => import('../modules/settings/ProductTypesPage'))
  const CompetitionPage = lazy(() => import('../modules/competitions/CompetitionPage'))
  const CompetitionTypePage = lazy(() => import('../modules/competitions/CompetitionTypePage'))
  const CompetitionRoundPage = lazy(() => import('../modules/competitions/CompetitionRoundPage'))
  const CompetitionExamineePage = lazy(() => import('../modules/competitions/CompetitionExamineePage'))
  const CompetitionTopicPage = lazy(() => import('../modules/competitions/CompetitionTopicPage'))

  const accessDiscount = useCanAccess('ProductDiscount', 'GetAll');
  const accessProduct = useCanAccess('Product', 'GetAll');
  const accessTeachers = useCanAccess('User', 'GetAll');
  const accessStudents = useCanAccess('User', 'GetAll');
  const accessSchedules = useCanAccess('Schedules', 'GetAll');
  const accessClasses = useCanAccess('LearningClass', 'GetAll');
  const accessCenters = useCanAccess('LearningCenter', 'GetAll');
  const accessRooms = useCanAccess('LearningRoom', 'GetAll');
  const accessAccounts = useCanAccess('User', 'GetAll');
  const accessVendors = useCanAccess('Vendor', 'GetAll');
  const accessProductTypes = useCanAccess('ProductType', 'GetAll');
  const accessNotifications = useCanAccess('NotificationSetting', 'GetAll');
  const accessVariantGroups = useCanAccess('VariantGroup', 'GetAll');
  const accessVariants = useCanAccess('Variant', 'GetAll');
  const accessRole = useCanAccess('System', 'GetAllRole');
  const accessCompetition = useCanAccess('LearningCompetition', 'GetAll');

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        {/*<Route path='/crafted/account' component={AccountPage} />*/}
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        
        {/*<Route path='/settings/product/discount' component={DiscountPage} />*/}
        {/*<Route path='/assets/products' component={ProductsPage} />*/}

        {/*<Route path='/learning/classes' component={LearningClassPage} />*/}
        {/*<Route path='/assets/teachers' component={TeachersPage} />*/}
        {/*<Route path='/assets/students' component={StudentsPage} />*/}
        
        {/*<Route path='/settings/accounts' component={AccountsPage} />*/}
        {/*<Route path='/settings/product/vendors' component={VendorsPage} />*/}

        {/*<Route path='/settings/learning/centers' component={LearningCenterPage} />*/}

        {/*<Route path='/settings/learning/rooms' component={LearningRoomPage} />*/}

        {/*<Route path='/settings/product/variant-groups' component={VariantGroupPage} />*/}

        {/*<Route path='/settings/product/variants' component={VariantPage} />*/}
        <ProtectedRoute path='/settings/product/variants' component={VariantPage} hasPermission={accessVariants} />
        {/*<Route path='/settings/product/product-types' component={ProductTypesPage} />*/}
        <ProtectedRoute path='/settings/product/product-types' component={ProductTypesPage} hasPermission={accessProductTypes} />
        {/*<Route path='/settings/notifications' component={NotificationSettingPage} />*/}
        <Route path='/notifications' component={NotificationPage} />

        {/*<Route path='/settings/roles' component={RolePage} />*/}

        <ProtectedRoute path="/settings/notifications" component={NotificationSettingPage} hasPermission={accessNotifications} />
        <ProtectedRoute path="/settings/roles" component={RolePage} hasPermission={accessRole} />

        {/*<ProtectedRoute path='/crafted/pages/profile' component={ProfilePage} hasPermission={accessRole}/>*/}
        {/*<ProtectedRoute path='/crafted/pages/wizards' component={WizardsPage} hasPermission={accessRole}/>*/}
        {/*<ProtectedRoute path='/crafted/widgets' component={WidgetsPage} hasPermission={accessRole}/>*/}
        <Route path='/crafted/account' component={AccountPage}/>
        {/*<ProtectedRoute path='/apps/chat' component={ChatPage} hasPermission={accessRole}/>*/}
        {/*<ProtectedRoute path='/menu-test' component={MenuTestPage} hasPermission={accessRole}/>*/}

        <ProtectedRoute path='/settings/product/discount' component={DiscountPage} hasPermission={accessDiscount}/>
        <ProtectedRoute path='/assets/products' component={ProductsPage} hasPermission={accessProduct}/>

        <ProtectedRoute path='/learning/classes' component={LearningClassPage} hasPermission={accessClasses}/>
        <ProtectedRoute path='/assets/teachers' component={TeachersPage} hasPermission={accessTeachers}/>
        <ProtectedRoute path='/assets/students' component={StudentsPage} hasPermission={accessStudents} />

        <Route path='/settings/accounts' component={AccountsPage} />
        <ProtectedRoute path='/settings/product/vendors' component={VendorsPage} hasPermission={accessVendors} />

        <ProtectedRoute path='/settings/learning/centers' component={LearningCenterPage} hasPermission={accessCenters} />

        <ProtectedRoute path='/settings/learning/rooms' component={LearningRoomPage} hasPermission={accessRooms} />

        <ProtectedRoute path='/settings/product/variant-groups' component={VariantGroupPage} hasPermission={accessVariantGroups} />

        <ProtectedRoute path='/competition/manage' component={CompetitionPage} hasPermission={accessCompetition} />
        <ProtectedRoute path='/competition/type' component={CompetitionTypePage} hasPermission={accessCompetition} />
        <ProtectedRoute path='/competition/round' component={CompetitionRoundPage} hasPermission={accessCompetition} />
        <ProtectedRoute path='/competition/examinee' component={CompetitionExamineePage} hasPermission={accessCompetition} />
        <ProtectedRoute path='/competition/topic' component={CompetitionTopicPage} hasPermission={accessCompetition} />

        <Route path='/learning/schedules' component={LearningSchedulePage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
